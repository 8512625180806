import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Collapse,
  Heading,
  HStack,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '../theme';
import { EditableHeader } from '../components/EditableHeader';
import { Slider } from '../components/Slider';
import { StringAdvanceCard } from './Moves';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';

function RemoveStringModal({ name, isOpen, onClose, onClick }) {
  const cancelRef = React.useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent layerStyle="base">
          <AlertDialogBody mt={5}>
            Remove Strings for{' '}
            <Heading
              textStyle="heading"
              className="accentWithHover"
              lineHeight={1.2}
              fontSize={{ base: 'xs', sm: 'sm' }}
              display="inline-block"
              mx={2}
            >
              {name}
            </Heading>
            ?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onClose();
                onClick();
              }}
              ml={3}
            >
              Remove
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function String({
  onChangeValue = () => {},
  onChangeName = () => {},
  onRemove = () => {},
  ...props
}) {
  const headingColor = useColorModeValue('accent.400', 'accent.200');
  const [showRemove, setShowRemove] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { name, value, max } = props;

  const isHighlighted = value === max;

  return (
    <HStack {...props}>
      <Slider
        onChange={onChangeValue}
        ariaPrefix={`${name} strings: `}
        {...props}
      />
      <HStack
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
        onFocus={() => setShowRemove(true)}
        onBlur={() => setShowRemove(false)}
      >
        <EditableHeader
          name={name}
          onSubmit={onChangeName}
          onRemove={onRemove}
          className="accentWithHover"
          lineHeight={1.2}
          fontSize={{ base: 'xs', sm: 'sm' }}
          color={isHighlighted && headingColor}
          transform={isHighlighted && 'scale(1.1)'}
          transition={'all .2s'}
          inputWidth="150px"
          inputFocus={{
            boxShadow: `0 0 0 3px ${transparentize('accent.500', 0.5)(theme)}`,
          }}
        />
        <Button
          visibility={showRemove ? '' : 'hidden'}
          aria-label={`Remove ${name} strings`}
          size="xs"
          ml={2}
          onClick={onOpen}
        >
          -
        </Button>
        <RemoveStringModal
          name={name}
          isOpen={isOpen}
          onClose={onClose}
          onClick={() => {
            console.log(`Remove ${name} strings`);
            onRemove();
          }}
        />
      </HStack>
    </HStack>
  );
}

export function StringList({
  strings = [],
  onChange = () => {},
  max,
  ...props
}) {
  function handleChange(string, i) {
    console.log(
      `${strings[i].name}: ${strings[i].value} -> ${string.name}: ${string.value}`
    );
    onChange([...strings.slice(0, i), string, ...strings.slice(i + 1)]);
  }
  function handleAdd(string) {
    onChange([...strings, string]);
  }
  function handleRemove(i) {
    console.log(`Remove ${strings[i].name}: ${strings[i].value}`);
    console.log([...strings.slice(0, i), ...strings.slice(i + 1)]);
    onChange([...strings.slice(0, i), ...strings.slice(i + 1)]);
  }

  const stringComps = strings.map((string, i) => (
    <String
      key={`${i}-strings`}
      name={string.name || ''}
      value={string.value || 0}
      max={max}
      onChangeName={value => {
        handleChange({ name: value, value: strings[i].value }, i);
      }}
      onChangeValue={value => {
        handleChange({ name: strings[i].name, value: value }, i);
      }}
      onRemove={() => handleRemove(i)}
      {...props}
    />
  ));

  return (
    <Stack direction="column">
      {stringComps}
      {strings && <Box />}
      <Center>
        <Button
          minWidth="150px"
          width="85%"
          onClick={() => handleAdd({ name: 'New Character', value: 0 })}
        >
          +
        </Button>
      </Center>
    </Stack>
  );
}

export function Strings(props) {
  const strings = useStoreState(CharacterStore, s => s.strings);
  const max = 4;
  const showAdvance = strings?.some(entry => entry.value === max);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction={{ base: 'column' }}
    >
      <StringList
        strings={strings}
        max={max}
        onChange={value => {
          CharacterStore.update(s => {
            s.strings = value;
          });
        }}
        {...props}
      />
      <Collapse in={showAdvance} animateOpacity>
        <Box p={5} display={!showAdvance && 'none'}>
          <StringAdvanceCard minWidth="200px" />
        </Box>
      </Collapse>
    </Stack>
  );
}

export function StringsOn(props) {
  const strings = useStoreState(CharacterStore, s => s.stringsOn);
  const max = 5;

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      direction={{ base: 'column' }}
    >
      <StringList
        strings={strings}
        max={max}
        onChange={value => {
          CharacterStore.update(s => {
            s.stringsOn = value;
          });
        }}
        {...props}
      />
    </Stack>
  );
}

export const StringsPanel = props => (
  <Center w="full">
    <Strings w="fit-content" />
  </Center>
);
