import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  Spacer,
  VStack,
} from '@chakra-ui/react';

import { PlaybookSelect } from './CharacterSettings';
import { Stats, XP } from './Stats';
import {
  PlaybookButton,
  PlaybookStats,
  hasPlaybookButton,
  hasPlaybookStats,
} from './PlaybookStats';
import { Conditions } from './Conditions';
import { Character } from './Character';
import { CombinedMoves, CoreMoves, PlaybookMoves, GMRefs } from './Moves';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';

const content = (name, gmMode = false) => ({
  ...{
    [name]: <Character />,
    'Playbook Moves': <PlaybookMoves />,
    'Basic Moves': <CombinedMoves />,
  },
  ...(gmMode && { 'GM Reference': <GMRefs /> }),
});

export function ContentAccordion({ title, moves, ...props }) {
  const { name, pronouns, gmMode } = useStoreState(CharacterStore, s => ({
    name: s.name,
    pronouns: s.pronouns,
    gmMode: s.GM,
  }));
  const charSectionName =
    name && pronouns ? `${name} — ${pronouns}` : name || '';
  const items = Object.entries(content(charSectionName, gmMode)).map(
    ([key, value]) => (
      <AccordionItem key={key} border={0}>
        <AccordionButton rounded="md">
          <Heading textStyle="heading" size="md" className="accentWithHover">
            {key}
          </Heading>
          <Spacer />
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel data-test-id={`accordion-content-${key}`}>
          {value}
        </AccordionPanel>
      </AccordionItem>
    )
  );

  return (
    <>
      <HStack justifyContent="center" spacing={10}>
        <XP />
        {gmMode && <PlaybookSelect />}
      </HStack>

      <Accordion allowToggle allowMultiple defaultIndex={[1]} {...props}>
        {items}
      </Accordion>
    </>
  );
}

export function HeaderAccordion(props) {
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const hasCustom = hasPlaybookStats(playbook);
  const hasButton = hasPlaybookButton(playbook);
  const Icon = isExpanded =>
    hasButton ? <PlaybookButton isExpanded={isExpanded} /> : <AccordionIcon />;

  const StatsStack = ({ isExpanded }) =>
    hasCustom || hasButton ? (
      <>
        <Stats />
        <HStack spacing={0}>
          <PlaybookStats isExpanded={isExpanded} justifySelf="center" m={2} />
          <AccordionButton rounded="md" width="fit-content">
            {Icon(isExpanded)}
          </AccordionButton>
        </HStack>
      </>
    ) : (
      <HStack spacing={0}>
        <Stats justifySelf="center" m={2} />
        <AccordionButton rounded="md" width="fit-content">
          {Icon(isExpanded)}
        </AccordionButton>
      </HStack>
    );

  return (
    <Accordion allowToggle allowMultiple defaultIndex={[1]} w="100%" {...props}>
      <AccordionItem border={0}>
        {({ isExpanded }) => (
          <>
            <VStack spacing={3} mb={3}>
              <Conditions />
              <StatsStack isExpanded={isExpanded} />
            </VStack>
            <AccordionPanel>
              <Box
                rounded="lg"
                p={5}
                flex="1"
                w="full"
                overflow="hidden"
                layerStyle="base"
              >
                <CoreMoves />
              </Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
