import React, { useState } from 'react';
import {
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
} from '@chakra-ui/react';

export function EditableHeader({
  name,
  placeholder,
  onSubmit,
  onRemove,
  isHighlighted = false,
  inputWidth,
  inputFocus,
  ...props
}) {
  const [tempValue, setTempValue] = useState(null);

  return (
    <Heading textStyle="heading" {...props}>
      <Editable
        value={tempValue ?? name}
        placeholder={placeholder}
        onChange={value => setTempValue(value)}
        onSubmit={value => {
          if (value && value !== name) {
            onSubmit(value);
          }
          setTempValue(null);
        }}
      >
        <EditablePreview />
        <EditableInput w={inputWidth} _focus={inputFocus} />
      </Editable>
    </Heading>
  );
}
