import { Store } from 'pullstate';
import { db } from './firebase';

import { updateGameplayStore } from './gameplayStore';

// eslint-disable-next-line no-unused-vars
const exampleCharacter = {
  name: 'Space Wurm',
  xp: 5,
  stats: {
    daring: 2,
    grace: 1,
    heart: -1,
    wit: 2,
    //spirit: 0
    feral: 4,
  },
  strings: [
    { name: 'Moonicorn', value: 2 },
    { name: 'The Xaverine', value: 3 },
    { name: 'Spiral', value: 2 },
  ],
  playbook: 'beast',
  moves: [
    'smittenkitten',
    'bloodytruth',
    'transform',
    'bde',
    'tenacious',
    'web',
    'bargaining',
  ],
  customMoves: {
    bargaining: {
      title: 'Bargaining',
      type: 'custom',
      description:
        '**When you bargain with an NPC in a ritual manner**, take +1 to parley with them. **When you bargain with another PC in a ritual manner**, you can offer them access to, or resources from, whichever sectors of society you control.\n\n**If they are just currency**, you can also offer them:\n- One use of your voice of authority, as if they were you.\n- They can resolve a bond with you and write a new one with someone else.\n\n**If they are treasure**, you can also offer them:\n- +1 forward to anything that involves you in some way.\n- One use of ceremony, as if they were you.\n- They can resolve a bond with someone else and write one with you.',
    },
    bde: {
      title: 'Big Dire Empress',
      type: 'custom',
      description:
        "Overwritten!!!!<br /><br /><span class='trigger-text'>When you make it clear to your foes that you’re the biggest threat</span>, then for the rest of the scene, whenever you roll a 10+, you may choose someone present to be impressed or intrigued with you. Once during the scene, when you gain a String on someone, gain an additional String on someone else who considers you an enemy.",
    },
  },
};

const fillerNames = [
  'Countess Mordrella Fang',
  'Cuddaliah',
  'Duchess Sugar',
  'Eliavarradrine',
  'Eustace',
  'Hamabe',
  'Kalyani',
  'Meowlody',
  'Mercedes Irene DuChamp',
  'Mistress Anderson',
  'Nana Batmaam',
  'Princess Arabella',
  'Raycoon Rapid',
  'Quela Canto',
  'Salmira, Guardian Lesbisnake',
  'Stronktopus',
  'Tentacula',
  'Vermillia',
  'Zephyr Celeste',
];

const getFillerName = () =>
  fillerNames[(fillerNames.length * Math.random()) | 0];

const getInitialCharacterState = name => {
  return {
    name: name,
  };
};

export const AppStateStore = new Store({
  isSubscribed: false,
  unsubscribe: () => {},
});

export const CharacterStore = new Store({ name: getFillerName() });

// update custom sections of GameplayStore
CharacterStore.createReaction(
  s => ({
    playbook: s.playbook,
    moveKeys: s.moves,
    conditions: s.conditions,
    customMoves: s.customMoves,
  }),
  s => {
    updateGameplayStore(s);
  }
);

export function subscribe(channel, character, create = false) {
  AppStateStore.currentState.unsubscribe();
  const subscriptions = [];

  if (!channel || !character) {
    return;
  }

  AppStateStore.update(s => {
    s.isSubscribed = false;
    s.isLoading = true;
    s.error = undefined;
  });

  // update character ref
  // query for a character of that name
  db.ref(channel)
    .child('characters')
    .orderByChild('name')
    .equalTo(character)
    .once('value')
    .then(function (snapshot) {
      let characterRef = null;

      if (snapshot.exists()) {
        console.log('Character exists');
        snapshot.forEach(function (childSnapshot) {
          characterRef = childSnapshot.ref;
        });
        AppStateStore.update(s => {
          s.isSubscribed = true;
        });
      } else if (create) {
        console.log('Creating new character');
        // if character with name does not exist, create a new one
        characterRef = db.ref(channel).child('characters').push().ref;
        characterRef.set(getInitialCharacterState(character));
        AppStateStore.update(s => {
          s.isSubscribed = true;
        });
      } else {
        throw new Error(`Unable to find ${character} in channel ${channel}`);
      }
      /*// purely for logging:
      characterRef.once('value').then(function (snapshot) {
        console.log(snapshot.val());
      });*/

      // subscribe to firebase store
      characterRef.on('value', function (snapshot) {
        if (snapshot.exists()) {
          CharacterStore.replace(snapshot.val());
          //console.log('firebase update');
          //console.log(snapshot.val());
        } else {
          // character has been deleted, reset data
          characterRef.set(
            getInitialCharacterState(CharacterStore.currentState.name)
          );
        }

        // sync with firebase
        subscriptions.push(
          CharacterStore.createReaction(
            s => s,
            (s, draft) => {
              if (!characterRef) {
                return;
              }
              characterRef.update(draft);
            }
          )
        );
      });

      subscriptions.push(() => characterRef.off());
    })
    .catch(e => {
      AppStateStore.update(s => {
        s.error = e.message;
      });
    })
    .finally(() => {
      AppStateStore.update(s => {
        s.isLoading = false;
      });
    });

  const unsubscribe = () => subscriptions.forEach(unsub => unsub());
  AppStateStore.update(s => {
    s.unsubscribe = unsubscribe;
  });

  return unsubscribe;
}
