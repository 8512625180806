import React, { useEffect } from 'react';
import { ChakraProvider, useDisclosure, useToast } from '@chakra-ui/react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { theme } from './theme';

import { SingleCharacterView } from './views/SingleCharacterView';
//import { MultiCharacterView } from './views/MultiCharacterView';
import { SignInModal } from './hocs/SignIn';

import { useStoreState } from 'pullstate';
import {
  AppStateStore,
  CharacterStore,
  subscribe,
} from './stores/characterStore';
import { MessageStore, subscribeChannelMessages } from './stores/messageStore';

function getPath(gameId, characterId) {
  if (gameId) {
    return characterId ? `/game/${gameId}/${characterId}` : `/game/${gameId}`;
  }
  return '/';
}

function RequireSubscribed({ children }) {
  let { gameId, characterId } = useParams();
  let { state } = useLocation();
  let navigate = useNavigate();
  const { name } = useStoreState(CharacterStore, s => ({
    name: s.name,
  }));
  const { channel } = useStoreState(MessageStore, s => ({
    channel: s.channel,
  }));

  const { isSubscribed, isLoading } = useStoreState(AppStateStore, s => ({
    isSubscribed: s.isSubscribed,
    isLoading: s.isLoading,
  }));

  useEffect(() => {
    if (gameId && characterId) {
      if (isSubscribed) {
        // if the character or channel name updates, update the url
        if (gameId !== channel || characterId !== name) {
          navigate(getPath(channel, name));
        }
      } else {
        subscribeChannelMessages(gameId); // cb array?
        return subscribe(gameId, characterId, state?.create);
      }
    }
  }, [gameId, characterId, channel, name, state, navigate, isSubscribed]);

  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: !isSubscribed,
    isOpen: !isSubscribed || isLoading,
  });

  return (
    <>
      <SignInModal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onSubmit={(gameId, characterId, create) =>
          navigate(getPath(gameId, characterId), { state: { create: create } })
        }
        data={{ channel: gameId, character: characterId }}
      />
      {children}
    </>
  );
}

function Main() {
  return <></>;
}

function Login() {
  return <>login</>;
}

function Game() {
  return <>game</>;
}

function GamePage() {
  return <SingleCharacterView />;
}

function CharacterPage() {
  return <SingleCharacterView />;
}

function LegacyGameUrl() {
  let { gameId } = useParams();
  let { search: characterId } = useLocation();
  const toast = useToast();
  const id = 'redirect-notice';

  characterId = characterId.substring(1); // remove ? from beginning

  let path = getPath(gameId, characterId);

  useEffect(() => {
    if (!toast.isActive(id)) {
      toast({
        title: 'TSL Roller urls are changing',
        description: (
          <>
            Please update your bookmark to <a href={path}>{decodeURI(path)}</a>
          </>
        ),
        status: 'warning',
        position: 'top',
        isClosable: true,
        duration: 9000,
      });
    }
  }, [toast, path]);

  return <Navigate to={path} replace />;
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/*<Route path="/" element={<Main />} />
          <Route path="login" element={<Login />} />
          <Route path="game" element={<Game />} />*/}
          <Route
            path="game/:gameId"
            element={
              <RequireSubscribed>
                <GamePage />
              </RequireSubscribed>
            }
          />
          <Route
            path="game/:gameId/:characterId"
            element={
              <RequireSubscribed>
                <CharacterPage />
              </RequireSubscribed>
            }
          />
          {/*<Route path="game/:gameId/gm" element={<>GM PAGE</>} />*/}
          <Route path=":gameId" element={<LegacyGameUrl />} />
          <Route
            path="*"
            element={
              <RequireSubscribed>
                <CharacterPage />
              </RequireSubscribed>
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
