import React from 'react';
import { Checkbox, Heading, HStack, Icon } from '@chakra-ui/react';
import { FaHeartBroken } from 'react-icons/fa';

import { GridFlex } from '../components/GridFlex';
import { Tooltip } from '../components/Tooltip';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import { GameplayStore } from '../stores/gameplayStore';

const ReactMarkdownWithHtml = require('react-markdown/with-html');

/* Example condition data:
  conditions: {
    angry: true,
    insecure: true
  },
*/

export function ConditionIcon(props) {
  // otherwise will get warnings:
  // React does not recognize the `isChecked`/`isIndeterminate` prop on a DOM element...
  const { isIndeterminate, isChecked, ...rest } = props;

  return <Icon as={FaHeartBroken} fontSize="" {...rest} />;
}

function ConditionCheckbox({ name, value, onChange, ...props }) {
  const label = value.description && (
    <ReactMarkdownWithHtml children={value.description} allowDangerousHtml />
  );

  return (
    <HStack px={3} {...props}>
      <Checkbox
        isChecked={value.checked}
        icon={<ConditionIcon />}
        iconSize={6}
        variant="ghost"
        onChange={e => onChange(e.target.checked)}
      >
        <Tooltip label={label}>
          <Heading
            textStyle="heading"
            lineHeight={1.2}
            fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
          >
            {name}
          </Heading>
        </Tooltip>
      </Checkbox>
    </HStack>
  );
}

export function ConditionsBlock({ conditions, onChange, ...props }) {
  const children = [
    Object.entries(conditions).map(([name, value]) => (
      <ConditionCheckbox
        key={name}
        name={name}
        value={value}
        onChange={val => {
          onChange(name, val);
        }}
      />
    )),
  ];

  return (
    <GridFlex minWidthPx={90} {...props}>
      {children}
    </GridFlex>
  );
}

export function Conditions(props) {
  const gameConds = useStoreState(GameplayStore, s => s.conditions);
  const charConds = useStoreState(CharacterStore, s => s.conditions);
  const conditions = {
    ...Object.keys(gameConds)?.reduce(
      (o, key) => ({
        ...o,
        [key]: { ...gameConds[key], checked: charConds?.[key] ?? false },
      }),
      {}
    ),
  };

  return (
    <ConditionsBlock
      conditions={conditions}
      onChange={(name, value) => {
        CharacterStore.update(s => {
          s.conditions = s.conditions ?? {};
          s.conditions[name] = value;
        });
      }}
      {...props}
    />
  );
}
