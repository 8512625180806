import React, { useState } from 'react';
import { Heading } from '@chakra-ui/react';

import { NumberTrack } from './NumberTrack';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function SelfDestructTrack({
  value,
  max,
  onChange = () => {},
  ...props
}) {
  const [tempVal, setTempVal] = useState(null);

  const showSelfDestruct = (tempVal || value) === max;

  return (
    <NumberTrack
      value={tempVal || value}
      label="Self-Destruct"
      onChange={val => {
        setTempVal(val);
        onChange(val);
      }}
      showHidden={showSelfDestruct}
      {...props}
    >
      <Heading
        textStyle="heading"
        as="i"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        Self Destruct!
      </Heading>
    </NumberTrack>
  );
}

export function SunHandStats(props) {
  const selfDestruct = useStoreState(
    CharacterStore,
    s => s?.stats?.selfDestruct
  );
  if (selfDestruct === null) {
    return null;
  }
  const max = 4;

  return (
    <SelfDestructTrack
      value={selfDestruct}
      max={max}
      onChange={val => {
        CharacterStore.update(s => {
          s.stats ?? (s.stats = {});
          s.stats.selfDestruct = val;
        });
      }}
      {...props}
    />
  );
}
