import React from 'react';
import { Heading } from '@chakra-ui/react';

export function ScoundrelButton({ isExpanded, props }) {
  const showText = ['Click Me~!', '*wink*', 'Dare Ya  ;D'];
  const hideText = ['Oops~', 'Did I Do That?', 'u////u'];

  function getRandomFrom(array) {
    return array[(array.length * Math.random()) | 0];
  }

  const text = isExpanded ? getRandomFrom(hideText) : getRandomFrom(showText);

  return (
    <Heading
      textStyle="heading"
      as="i"
      lineHeight={1.2}
      fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      {...props}
    >
      {text}
    </Heading>
  );
}
