import { Box, Heading, useColorModeValue } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

export function Modifer({ modifierClass, value }) {
  const bgColor = useColorModeValue(
    'purple.600',
    transparentize('purple.200', 0.16)
  );
  const color = useColorModeValue('purple.600', 'purple.200');

  const plusSignIfPositive = value > 0 ? '+' : '';

  return (
    <Box
      className={`modifier-${modifierClass}`}
      color={color}
      borderColor={bgColor}
      bg="hidden"
      p={1.5}
      w="50px"
      h="50px"
    >
      <Heading
        aria-label={value}
        className={`modifier-number-${modifierClass}`}
        textAlign="center"
      >
        {plusSignIfPositive + value}
      </Heading>
    </Box>
  );
}
