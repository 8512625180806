import React, { useState, createRef } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ClearableInput } from '../components/ClearableInput';
import { MoveCard } from '../components/MoveCard';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../stores/gameplayStore';

// also compare on description?
function getMoveText(move) {
  return move?.title || '';
}

function doesMatch(string1, string2, caseSensitive = false) {
  if (caseSensitive) {
    return string1.includes(string2);
  }
  return string1.toLocaleLowerCase().includes(string2.toLocaleLowerCase());
}

export function MoveGrid({ title, moves, ...props }) {
  const minChildWidthPx = useBreakpointValue({ base: 200, sm: 300 });
  if (!moves) {
    return null;
  }

  var moveCards = [];
  if (Array.isArray(moves)) {
    moveCards = moves.reduce(
      (o, move, i) => [...o, { key: `move-${i}`, move: move }],
      []
    );
  } else if (typeof moves === 'object') {
    moveCards = Object.entries(moves).reduce(
      (o, [moveName, move]) => [...o, { key: moveName, move: move }],
      []
    );
  }

  return (
    <TransitionGroup
      // style as <SimpleGrid spacing={8} minChildWidth="300px" height="fit-content"
      style={{
        display: 'grid',
        gridGap: '2rem',
        gridTemplateColumns: `repeat(auto-fit, minmax(${minChildWidthPx}px, 1fr))`,
        height: '-webkit-fit-content',
        // eslint-disable-next-line no-dupe-keys
        height: '-moz-fit-content',
        // eslint-disable-next-line no-dupe-keys
        height: 'fit-content',
        justifyItems: 'center',
      }}
      {...props}
    >
      {moveCards &&
        moveCards.map(({ key, move }) => {
          const itemRef = createRef(null);
          return (
            <CSSTransition
              nodeRef={itemRef}
              key={key}
              timeout={500}
              classNames="my-node"
            >
              <MoveCard
                ref={itemRef}
                move={move}
                transition="all .2s ease-in-out"
                _hover={{ transform: 'translate(0, -1%)' }}
              />
            </CSSTransition>
          );
        })}
    </TransitionGroup>
  );
}

function FilterableMoveGrid({ moves, ...props }) {
  const [value, setValue] = useState('');
  if (!moves) {
    return null;
  }

  return (
    <>
      <Box mx={3} mb={3}>
        <ClearableInput
          value={value}
          onChange={setValue}
          placeholder="Filter moves"
        />
      </Box>
      <MoveGrid
        moves={
          value
            ? Object.entries(moves)
                .filter(([_, move]) => doesMatch(getMoveText(move), value))
                .reduce((obj, [key, move]) => {
                  return { ...obj, [key]: move };
                }, {})
            : moves
        }
      />
    </>
  );
}

export function StringAdvanceCard(props) {
  const move = useStoreState(
    GameplayStore,
    s => s.character['basic moves']?.stringadv
  );

  return <MoveCard move={move} {...props} />;
}

export function CoreMoves(props) {
  const moves = useStoreState(GameplayStore, s => s.character['core moves']);
  return <MoveGrid moves={moves} {...props} />;
}

export function BasicMoves(props) {
  const moves = useStoreState(GameplayStore, s => s['basic moves']);
  return <FilterableMoveGrid moves={moves} {...props} />;
}

export function PlaybookMoves(props) {
  const moves = useStoreState(
    GameplayStore,
    s => s.character['character moves']
  );
  return <FilterableMoveGrid moves={moves} {...props} />;
}

export function AllPlaybookMoves(props) {
  const moves = Object.fromEntries(
    Object.entries(
      useStoreState(GameplayStore, s => s['playbook moves'])
    ).reverse()
  );
  return <FilterableMoveGrid moves={moves} {...props} />;
}

export function CombinedMoves(props) {
  const moves = useStoreState(GameplayStore, s => s.character['basic moves']);

  return <FilterableMoveGrid moves={moves} {...props} />;
}

export function GMRefs(props) {
  const moves = useStoreState(GameplayStore, s => s.gm);

  return <FilterableMoveGrid moves={moves} {...props} />;
}
