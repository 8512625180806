import app from 'firebase/app';
// eslint-disable-next-line no-unused-vars
import database from 'firebase/database';

import { firebaseConfig } from '../firebaseConfig.js';

app.initializeApp(firebaseConfig);

export const db = app.database();
if (process.env.REACT_APP_USE_EMULATORS) {
  db.useEmulator('localhost', 9000);
  console.log('Using database emulator on localhost:9000');
}
