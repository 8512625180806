import React from 'react';
import { Icon } from '@chakra-ui/react';
import { GoEye } from 'react-icons/go';
import { RiEyeCloseFill } from 'react-icons/ri';

export function SpookyWitchButton({ isExpanded, ...props }) {
  const text = isExpanded ? 'Hide' : 'Show';
  const SwitchIcon = isExpanded ? RiEyeCloseFill : GoEye;

  return (
    <Icon
      as={SwitchIcon}
      boxSize={8}
      aria-label={`${text} Spooky Witch core moves`}
      variant="ghost"
      color="current"
      {...props}
    />
  );
}
