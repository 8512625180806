import { Store } from 'pullstate';
const tsl = require('../gamedata/tsl.json');

export const GameplayStore = new Store({ ...tsl, character: {} });
updateGameplayStore(GameplayStore, {});

function getMovesByKey(moves, moveKeys) {
  return (
    moveKeys?.reduce(
      (o, key) =>
        key && moves.hasOwnProperty(key)
          ? { ...o, [key]: moves[key] }
          : { ...o },
      {}
    ) || {}
  );
}

function getMovesByType(moves, type = '', subtype = '') {
  return Object.entries(moves || {}).reduce(
    (o, [key, value]) =>
      value.type === type && (!subtype || value.subtype === subtype)
        ? { ...o, [key]: value }
        : { ...o },
    {}
  );
}

function getMovesBySubtype(moves, subtype = '') {
  return Object.entries(moves || {}).reduce(
    (o, [key, value]) =>
      !subtype || value.subtype === subtype ? { ...o, [key]: value } : { ...o },
    {}
  );
}

function getBasicMoves(moves) {
  return getMovesByType(moves, 'basic');
}

function getPlaybookMoves(moves, playbook) {
  return {
    ...getMovesByType(moves, playbook, 'core'),
    ...getMovesByType(moves, playbook, 'truth'),
    ...getMovesByType(moves, playbook, 'default'),
  };
}

function getCoreMoves(moves, playbook) {
  return getMovesByType(moves, playbook, 'core');
}

// eslint-disable-next-line no-unused-vars
function getTruthMoves(moves, playbook) {
  return getMovesByType(moves, playbook, 'truth');
}

function getDefaultMoves(moves, playbook) {
  return getMovesByType(moves, playbook, 'default');
}

// [playbook] core moves cannot be taken as an advance,
// [playbook] default and playbook moves can
export function getAdvanceableMoves(moves) {
  return {
    ...getMovesBySubtype(moves, 'default'),
    ...getMovesBySubtype(moves, 'playbook'),
  };
}

/*
returns {
  modifiedMoveKey: {
    moves: [modifyingMoveKey, modifyingMoveKey]
    conditions: [conditionKey]
  }
}
*/
function getModifierMap(moves = {}, conditions = {}) {
  function addToMap(modifiedMoveKey, modifyingMoveKey, type) {
    if (typeof modifiedMoveKey === 'string') {
      modifiedMoveKey = [modifiedMoveKey];
    }
    modifiedMoveKey.forEach(subKey => {
      modMap[subKey] ?? (modMap[subKey] = {});
      modMap[subKey][type] = [
        ...(modMap[subKey][type] ?? []),
        modifyingMoveKey,
      ];
    });
  }

  var modMap = {};
  Object.entries(moves).forEach(
    ([key, move]) => move.modifies && addToMap(move.modifies, key, 'moves')
  );
  Object.entries(conditions).forEach(
    ([key, condition]) =>
      condition.modifies && addToMap(condition.modifies, key, 'conditions')
  );
  return modMap;
}

export function getModifiedMoves(moves, conditions, modifierMap) {
  var modifiedMoves = {};
  for (const [modifiedMoveKey, map] of Object.entries(modifierMap)) {
    var move = { ...moves[modifiedMoveKey] };
    move.modifiers = {};
    if (map.moves) {
      move.modifiers.moves = map.moves.map(
        modifyingMoveKey => moves[modifyingMoveKey]
      );
    }
    if (map.conditions) {
      move.modifiers.conditions = map.conditions.map(
        modifyingConditionKey => conditions[modifyingConditionKey]
      );
    }
    modifiedMoves[modifiedMoveKey] = move;
  }
  return { ...moves, ...modifiedMoves };
}

function getConditions(charConditions, conditions) {
  return Object.keys(charConditions ?? {}).reduce(
    (o, key) =>
      charConditions[key] ? { ...o, [key]: conditions?.[key] } : { ...o },
    {}
  );
}

function combineCharacterAndGameplay(characterStore, gameplayStore) {
  // custom moves should overwrite generic gameplay moves
  var allMoves = { ...gameplayStore.moves, ...characterStore.customMoves };
  const conditions = getConditions(
    characterStore.conditions,
    gameplayStore.conditions
  );
  const modifierMap = getModifierMap(
    {
      ...getBasicMoves(allMoves), // unmodified basic moves
      ...getPlaybookMoves(allMoves, characterStore.playbook), // unmodified core moves
      ...getMovesByKey(allMoves, characterStore.moveKeys), // unmodified character moves
    },
    conditions
  );
  const modifiedMoves = getModifiedMoves(allMoves, conditions, modifierMap);

  return {
    'core moves': getCoreMoves(modifiedMoves, characterStore.playbook),
    'character moves': {
      ...getDefaultMoves(modifiedMoves, characterStore.playbook),
      ...getMovesByKey(modifiedMoves, characterStore.moveKeys),
    },
    'basic moves': getBasicMoves(modifiedMoves),
  };
}

export function updateGameplayStore(characterStore) {
  GameplayStore.update(t => {
    t.character = combineCharacterAndGameplay(
      characterStore,
      GameplayStore.currentState
    );
  });
}
