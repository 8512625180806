import React from 'react';
import {
  Box,
  Button,
  Input,
  Heading,
  HStack,
  VStack,
  useColorModeValue,
  useNumberInput,
} from '@chakra-ui/react';

import { GridFlex } from '../components/GridFlex';
import { Tooltip } from '../components/Tooltip';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import { GameplayStore } from '../stores/gameplayStore';

/* Example stats data:
  stats: {
    daring: 2,
    grace: 1,
    heart: -1,
    wit: 2,
    //spirit: 0
    feral: 4,
  },
*/

function NumberInputStatBlock({ name, value, onChange, min, max, ...props }) {
  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({
    step: 1,
    value: value,
    min: min,
    max: max,
    onChange: onChange,
  });
  const buttonVariant = useColorModeValue('subtle', 'solid');
  const buttonProps = {
    size: 'sm',
    colorScheme: 'accent',
    variant: buttonVariant,
  };
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack spacing={4} {...props}>
      <HStack maxW="130px">
        <Button {...buttonProps} {...dec}>
          -
        </Button>
        <Input {...input} textAlign="center" focusBorderColor="accent.500" />
        <Button {...buttonProps} {...inc}>
          +
        </Button>
      </HStack>
      <Heading
        textStyle="heading"
        className="accentWithHover"
        lineHeight={1.2}
        fontSize={{ base: 'sm', sm: 'md', md: 'xl' }}
      >
        {name}
      </Heading>
    </HStack>
  );
}

function StatBlock({ name, value, description, ...props }) {
  const label = description;
  return (
    <VStack p={3} {...props}>
      <Box lineHeight={1} fontSize={{ base: 'sm', sm: 'md', md: 'xl' }}>
        {value > 0 && '+'}
        {value}
      </Box>
      <Tooltip label={label}>
        <Heading
          textStyle="heading"
          lineHeight={1.2}
          fontSize={{ base: 'sm', sm: 'md', md: 'xl' }}
          _hover={{
            transform: 'scale(1.02)',
            transition: 'transform 200ms',
          }}
        >
          {name}
        </Heading>
      </Tooltip>
    </VStack>
  );
}

export function StatsBlock({ stats, ...props }) {
  const children = [
    Object.entries(stats).map(([name, value]) => (
      <StatBlock
        key={name}
        name={name}
        value={value.value}
        description={value.description}
      />
    )),
  ];

  return (
    <GridFlex minWidthPx={90} {...props}>
      {children}
    </GridFlex>
  );
}

export function XP(props) {
  const xp = useStoreState(CharacterStore, s => s.xp);

  return (
    <NumberInputStatBlock
      name="XP"
      value={xp}
      min={0}
      onChange={(_, value) => {
        CharacterStore.update(s => {
          s.xp = value;
        });
      }}
    />
  );
}

export function Stats(props) {
  const gameStats = useStoreState(GameplayStore, s => s.stats);
  const charStats = useStoreState(CharacterStore, s => s.stats);
  const stats = {
    // [stat1, stat2, stat3] => {stat1: x, stat2: y, stat3: z}
    ...Object.keys(gameStats)?.reduce(
      (o, key) => ({
        ...o,
        [key]: { ...gameStats[key], value: charStats?.[key] ?? 0 },
      }),
      {}
    ),
  };

  return <StatsBlock stats={stats} {...props} />;
}
