import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  useId,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '../theme';

import { capitalize } from '../functions/utils';

const defaultColor = 'gray';

function RadioButton(props) {
  const {
    value,
    colorScheme,
    variant,
    selectedColor,
    onClick,
    ...rest
  } = props;
  const children = props.content || capitalize(value);
  const id = useId(props.id);
  const { getInputProps, getCheckboxProps } = useRadio({ id, ...props });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const buttonProps = {
    'aria-label': value,
    as: 'label',
    htmlFor: input.id,
    variant: variant,
    _checked: {
      color: 'white',
      bg: (selectedColor || colorScheme || defaultColor) + '.500',
    },
    _focus: {
      boxShadow: `0 0 0 3px ${transparentize('accent.500', 0.6)(theme)}`,
    },
  };

  return (
    <>
      <Button
        {...checkbox}
        {...buttonProps}
        onClick={() => onClick(value)}
        {...rest}
        colorScheme={colorScheme}
      >
        {children}
      </Button>
      <input {...input} />
    </>
  );
}

export function RadioGroup({
  name,
  options,
  selected,
  onChange,
  colorScheme,
  variant,
  selectedColor,
  isAttached,
  isToggleable,
  ...rest
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    value: selected,
  });

  function handleClick(value) {
    if (isToggleable && selected === value) {
      onChange(undefined);
    } else {
      onChange(value);
    }
  }

  const group = getRootProps();

  return (
    <ButtonGroup isAttached={isAttached} {...group}>
      {Object.entries(options).map(([option, data]) => (
        <RadioButton
          {...getRadioProps({ value: option })}
          key={option}
          value={option}
          content={data.content ?? capitalize(option)}
          colorScheme={data.isHighlighted ? colorScheme : defaultColor}
          variant={variant}
          selectedColor={selectedColor}
          children={option}
          onClick={handleClick}
          {...rest}
        />
      ))}
    </ButtonGroup>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  selected: PropTypes.string,
  //onChange: PropTypes.func, // breaks using onChange when uncommented?
  isAttached: PropTypes.any,
  isToggleable: PropTypes.any,
};

RadioGroup.defaultProps = {
  selected: '',
  isAttached: false,
  isToggleable: true,
};
