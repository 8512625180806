import React from 'react';
import { Checkbox, Heading, Stack, Text, VStack } from '@chakra-ui/react';

export function CheckboxStack({
  heading,
  options,
  onChange = () => {},
  crossOut = false,
  ...props
}) {
  return (
    <VStack {...props}>
      {heading && (
        <Heading
          textStyle="heading"
          lineHeight={1.2}
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
        >
          {heading}
        </Heading>
      )}
      <Stack>
        {options.map((val, i) => (
          <Checkbox
            key={i}
            isChecked={val.checked}
            size="sm"
            colorScheme="accent"
            onChange={e => onChange(val, e)}
          >
            <Text as={crossOut && val.checked && 's'}>{val.text}</Text>
          </Checkbox>
        ))}
      </Stack>
    </VStack>
  );
}
