import React from 'react';
import {
  Flex as ChakraFlex,
  SimpleGrid,
  useMediaQuery,
} from '@chakra-ui/react';

function Grid({ children, ...props }) {
  return (
    <SimpleGrid
      gridAutoFlow="column"
      gridAutoColumns="max-content"
      spacing={1}
      justifyContent="center"
      w="fit-content"
      h="fit-content"
      {...props}
    >
      {children}
    </SimpleGrid>
  );
}

function Flex({ children, ...props }) {
  return (
    <ChakraFlex wrap="wrap" justifyContent="center" {...props}>
      {children}
    </ChakraFlex>
  );
}

export function GridFlex({ minWidthPx, children, ...props }) {
  const minChildWidth = minWidthPx ?? 90; //px
  const minGridWidth = minChildWidth * React.Children.count(children);
  // TODO: rewrite for when GridFlex width < window width
  const [isLargerThanGridMin] = useMediaQuery(`(min-width: ${minGridWidth}px)`);

  return isLargerThanGridMin ? (
    <Grid {...props}>{children}</Grid>
  ) : (
    <Flex {...props}>{children}</Flex>
  );
}
