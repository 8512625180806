import React, { useState } from 'react';
import {
  Box,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '../theme';

export function Slider({
  name,
  value,
  min = 0,
  max = 4,
  step = 1,
  onChange = () => {},
  useBase = false,
  ariaPrefix,
  ...props
}) {
  const [sliderValue, setSliderValue] = useState(null);

  const sliderWidth = (30 * (max - min)) / step;
  const unfilledTrackColor = useColorModeValue(
    'accent.50',
    useBase ? 'gray.700' : transparentize('accent.200', 0.16)
  );
  const thumbHover = useColorModeValue(
    {
      _hover: {
        bg: 'accent.400',
        transition: 'background-color 200ms;',
      },
    },
    {}
  );

  return (
    <ChakraSlider
      aria-label={`${ariaPrefix || ''}${sliderValue ?? value}`}
      value={sliderValue ?? value}
      min={min}
      max={max}
      step={step || 1}
      colorScheme="accent"
      width={`${sliderWidth}px`}
      ml={'20px'}
      mr={'30px'}
      onChange={setSliderValue}
      onChangeEnd={v => {
        if (v !== value) {
          setSliderValue(null);
          onChange(v);
        }
      }}
    >
      <SliderTrack overflow="visible" bg="transparent">
        {/* SliderThumb goes beyond the end of SliderTrack,
      so extend it with extra Box*/}
        <Box
          position="relative"
          left={3}
          height="100%"
          rounded="sm"
          bg={unfilledTrackColor}
        />

        <SliderFilledTrack rounded="sm" />
      </SliderTrack>
      <SliderThumb
        _focus={{
          boxShadow: `0 0 0 3px ${transparentize('accent.500', 0.6)(theme)}`,
        }}
        boxSize={7}
        layerStyle="base"
        transform={
          sliderValue === max || value === max
            ? 'scale(1.2) translateY(-50%)'
            : 'translateY(-50%)'
        }
      >
        <Tag
          colorScheme="accent"
          {...thumbHover}
          borderRadius="full"
          variant="solid"
        >
          <Text fontSize="xs" fontWeight="bold">
            {sliderValue ?? value}
          </Text>
        </Tag>
      </SliderThumb>
    </ChakraSlider>
  );
}
