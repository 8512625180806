/* DO NOT COMMIT THIS FILE WITH CONFIG DATA */

var databaseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://tsl-roller.firebaseio.com'
    : 'https://tsl-roller-default-rtdb.firebaseio.com';

export const firebaseConfig = {
  apiKey: 'AIzaSyDHoCZaH0sFfI-oeVfs-Xp-Xu8u_Tpqkg0',
  authDomain: 'tsl-roller.firebaseapp.com',
  databaseURL: databaseURL,
  projectId: 'tsl-roller',
  storageBucket: 'tsl-roller.appspot.com',
  messagingSenderId: '50635001679',
  appId: '1:50635001679:web:f0e500e9203a431175bf3a',
};
