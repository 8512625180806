import React, { useState } from 'react';
import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { CheckboxStack } from '../../components/CheckboxStack';
import { HiddenContent } from '../../components/HiddenContent';
import { uuidv4 } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

function EditableVow({
  text,
  onChange = () => {},
  onRemove = () => {},
  ...props
}) {
  const [showRemove, setShowRemove] = useState(false);
  const [tempValue, setTempValue] = useState(null);

  return (
    <HStack {...props}>
      <HStack
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
        onFocus={() => setShowRemove(true)}
        onBlur={() => setShowRemove(false)}
      >
        <Editable
          value={tempValue ?? text}
          onChange={value => setTempValue(value)}
          onSubmit={value => {
            if (value && value !== text) {
              onChange(value);
            }
            setTempValue(null);
          }}
          pl="32px"
        >
          <EditablePreview />
          <EditableInput minW="150px" maxW="100%" />
        </Editable>
        <Button
          visibility={showRemove ? '' : 'hidden'}
          aria-label={`Remove vow: ${text}`}
          size="xs"
          ml={2}
          onClick={onRemove}
        >
          X
        </Button>
      </HStack>
    </HStack>
  );
}

function AbandonedVow({ vow, onRemove = () => {}, ...props }) {
  const [showRemove, setShowRemove] = useState(false);

  return (
    <HStack {...props}>
      <HStack
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
        onFocus={() => setShowRemove(true)}
        onBlur={() => setShowRemove(false)}
      >
        <Text as="s" key={vow.id} pl="32px">
          {vow.text}
        </Text>
        <Button
          visibility={showRemove ? '' : 'hidden'}
          aria-label={`Remove abandoned vow: ${vow.text}`}
          size="xs"
          ml={2}
          onClick={onRemove}
        >
          X
        </Button>
      </HStack>
    </HStack>
  );
}

export function VowsList({ vows = [], onChange = () => {}, ...props }) {
  function handleChange(vow, i) {
    onChange([
      ...vows.slice(0, i),
      { ...vows[i], text: vow },
      ...vows.slice(i + 1),
    ]);
  }
  function handleAdd(vow) {
    onChange([...vows, vow]);
  }
  function handleRemove(i) {
    onChange([...vows.slice(0, i), ...vows.slice(i + 1)]);
  }

  const vowComps = vows.map((vow, i) =>
    vow.resolved ? null : (
      <EditableVow
        key={`${i}-vows`}
        text={vow.text || ''}
        onChange={value => {
          handleChange(value, i);
        }}
        onRemove={() => handleRemove(i)}
        {...props}
      />
    )
  );

  return (
    <VStack w="100%">
      {vows.length > 0 && (
        <>
          <Heading
            textStyle="heading"
            lineHeight={1.2}
            fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
          >
            Vows
          </Heading>
          {vowComps}
          <Box />
        </>
      )}
      <Button
        minWidth="150px"
        width="85%"
        fontSize="sm"
        onClick={() =>
          handleAdd({
            id: uuidv4(),
            text: 'New vow',
            checked: false,
            resolved: false,
          })
        }
      >
        Add vow
      </Button>
    </VStack>
  );
}

export function InfamousControls(props) {
  const vows = useStoreState(CharacterStore, s => s.stats?.vows || []);
  const abandonedVows = vows.reduce(
    (o, val) => (val.resolved ? [...o, val] : [...o]),
    []
  );

  return (
    <Stack fontSize="sm" {...props}>
      <Box>
        You swore to never again perform certain actions that could lead to
        harm. What are they? Examples include: Lying, stealing, accepting
        someone’s love, drawing blood, or breaking a promise. If you break your
        vow, you <span className="move-ref">Stagger</span>. Then decide whether
        to keep the vow or abandon it.
      </Box>
      <VStack spacing={10}>
        <VowsList
          vows={vows}
          onChange={value => {
            CharacterStore.update(s => {
              s.stats ?? (s.stats = {});
              s.stats.vows = value;
            });
          }}
        />
        {abandonedVows.length > 0 && (
          <VStack>
            <Heading
              textStyle="heading"
              lineHeight={1.2}
              fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
            >
              Abandoned Vows
            </Heading>
            {abandonedVows.map(vow => (
              <AbandonedVow
                key={vow.id}
                vow={vow}
                onRemove={() => {
                  CharacterStore.update(s => {
                    s.stats ?? (s.stats = {});
                    s.stats.vows ?? (s.stats.vows = []);

                    s.stats.vows = s.stats.vows.filter(
                      item => item.id !== vow.id
                    );
                  });
                }}
              />
            ))}
          </VStack>
        )}
      </VStack>
    </Stack>
  );
}

function VowResolve({ val, onChange, props }) {
  return (
    <HStack w="100%" {...props}>
      <Text fontSize="sm" as="i" maxW="200px" mr={2}>
        {val.text}
      </Text>
      <Spacer />
      <Button
        size="xs"
        color="current"
        colorScheme="accent"
        onClick={() => onChange(true)}
      >
        Keep
      </Button>
      <Button
        size="xs"
        color="current"
        colorScheme="accent"
        onClick={() => onChange(false)}
      >
        Abandon
      </Button>
    </HStack>
  );
}

export function InfamousStats(props) {
  const vows = useStoreState(CharacterStore, s => s?.stats?.vows)?.filter(
    item => !item.resolved
  );
  if (vows == null) {
    return null;
  }

  function handleChange(val, attr, isChecked) {
    CharacterStore.update(s => {
      s.stats ?? (s.stats = {});
      s.stats.vows ?? (s.stats.vows = []);
      s.stats.vows = s.stats.vows.map(item => {
        if (item.id === val.id) {
          item[attr] = isChecked;
        }
        if (item.checked === false) {
          item.resolved = false;
        }
        return item;
      });
    });
  }

  const hiddenContent = vows.map(
    item =>
      item.checked &&
      !item.resolved && (
        <VowResolve
          key={item.id}
          val={item}
          onChange={keep =>
            keep
              ? handleChange(item, 'checked', false)
              : handleChange(item, 'resolved', true)
          }
        />
      )
  );

  return (
    <HiddenContent
      visible={
        <Box
          fontSize=".875rem"
          minWidth="min(max-content, 300px)"
          align="center"
          justify="center"
        >
          <CheckboxStack
            options={vows}
            onChange={(val, e) =>
              handleChange(val, 'checked', e.target.checked)
            }
            maxW="200px"
            textAlign="left"
            crossOut
          />
        </Box>
      }
      hidden={
        <VStack ml={2}>
          <Heading
            textStyle="heading"
            as="i"
            lineHeight={1.2}
            fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
          >
            Stagger!
          </Heading>
          <Box fontSize="sm">Keep or Abandon your vow?</Box>
          <VStack spacing={1}>
            {hiddenContent.map((child, i) => (
              <Box key={i} w="100%">
                {child}
              </Box>
            ))}
          </VStack>
        </VStack>
      }
      showHidden={vows.some(item => item.checked)}
    />
  );
}
