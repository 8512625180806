import React, { useState } from 'react';
import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  List,
  ListItem,
  OrderedList,
  Stack,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

import { uuidv4 } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function DevotedSetup(props) {
  return <DevotedControls withDescription {...props} />;
}

function Devotion() {
  const accent = useColorModeValue('accentLight', 'accentDark');

  const charDevotion = useStoreState(CharacterStore, s => s.stats?.devotion);

  const devotionStyle = charDevotion
    ? { fontWeight: 'bold' }
    : { color: accent, fontStyle: 'italic' };

  const [tempDevotion, setTempDevotion] = useState(null);

  return (
    <Box>
      <List>
        <ListItem>
          <b>To a cause</b>: Freedom, vengeance, justice, love, the gay agenda
        </ListItem>
        <ListItem>
          <b>To a person</b>: A PC, a liege, an idol
        </ListItem>
        <ListItem>
          <b>To a higher power</b>: A god, a sexy dragon, a sentient planet
        </ListItem>
      </List>
      <Editable
        placeholder="(Choose one or invent your own)"
        textAlign="center"
        value={tempDevotion ?? charDevotion}
        onChange={val => setTempDevotion(val)}
        onSubmit={val => {
          CharacterStore.update(s => {
            s.stats ?? (s.stats = {});
            s.stats.devotion = val;
          });
          setTempDevotion(null);
        }}
      >
        <EditablePreview {...devotionStyle} />
        <EditableInput />
      </Editable>
    </Box>
  );
}

function EditableTenet({
  text,
  onChange = () => {},
  onRemove = () => {},
  ...props
}) {
  const [showRemove, setShowRemove] = useState(false);
  const [tempValue, setTempValue] = useState(null);

  return (
    <HStack {...props}>
      <HStack
        onMouseEnter={() => setShowRemove(true)}
        onMouseLeave={() => setShowRemove(false)}
        onFocus={() => setShowRemove(true)}
        onBlur={() => setShowRemove(false)}
      >
        <Editable
          value={tempValue ?? text}
          onChange={value => setTempValue(value)}
          onSubmit={value => {
            if (value && value !== text) {
              onChange(value);
            }
            setTempValue(null);
          }}
          pl="32px"
        >
          <EditablePreview />
          <EditableInput minW="150px" maxW="100%" />
        </Editable>
        <Button
          visibility={showRemove ? '' : 'hidden'}
          aria-label={`Remove vow: ${text}`}
          size="xs"
          ml={2}
          onClick={onRemove}
        >
          X
        </Button>
      </HStack>
    </HStack>
  );
}

export function TenetsList({ tenets = [], onChange = () => {}, ...props }) {
  function handleChange(tenet, i) {
    onChange([
      ...tenets.slice(0, i),
      { ...tenets[i], text: tenet },
      ...tenets.slice(i + 1),
    ]);
  }
  function handleAdd(tenet) {
    onChange([...tenets, tenet]);
  }
  function handleRemove(i) {
    onChange([...tenets.slice(0, i), ...tenets.slice(i + 1)]);
  }

  const tenetComps = tenets.map((tenet, i) => (
    <Box key={`${i}-tenets`} display="flex" alignItems="center">
      {i + 1}.
      <EditableTenet
        text={tenet.text || ''}
        onChange={value => {
          handleChange(value, i);
        }}
        onRemove={() => handleRemove(i)}
        {...props}
      />
    </Box>
  ));

  return (
    <VStack w="100%">
      {tenets.length > 0 && (
        <>
          <Heading
            textStyle="heading"
            lineHeight={1.2}
            fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
          >
            Tenets
          </Heading>
          <List>{tenetComps}</List>
          <Box />
        </>
      )}
      <Button
        minWidth="150px"
        width="85%"
        fontSize="sm"
        onClick={() =>
          handleAdd({
            id: uuidv4(),
            text: 'New tenet',
          })
        }
      >
        Add tenet
      </Button>
    </VStack>
  );
}

function Tenets() {
  const tenets = useStoreState(CharacterStore, s => s.stats?.tenets);

  return (
    <Box>
      What three tenets of your Devotion have you found yourself tempted to
      violate?
      <TenetsList
        tenets={tenets}
        onChange={value => {
          CharacterStore.update(s => {
            s.stats ?? (s.stats = {});
            s.stats.tenets = value;
          });
        }}
      />
    </Box>
  );
}

export function DevotedControls({ withDescription = false, ...props }) {
  return (
    <Stack fontSize="sm" {...props}>
      <Devotion />
      <Tenets />
      <ul>
        <li>
          Mark a Condition if you act contrary to your Devotion, for instance by
          violating its tenets or disobeying a superior.
        </li>
        <li>
          When you <span className="move-ref">Defy Disaster</span>, you may
          bring a subject of your Devotion with you safely.
        </li>
      </ul>
    </Stack>
  );
}

export function DevotedStats() {
  const devotion = useStoreState(CharacterStore, s => s?.stats?.devotion) || '';

  const tenets = useStoreState(CharacterStore, s => s.stats?.tenets);

  return (
    <VStack spacing={2}>
      {devotion && (
        <Heading
          textStyle="heading"
          as="i"
          lineHeight={1.2}
          fontSize={{ base: 'md', md: 'lg' }}
        >
          {devotion}
        </Heading>
      )}
      {tenets && (
        <OrderedList>
          {tenets.map(tenet => (
            <ListItem>{tenet.text}</ListItem>
          ))}
        </OrderedList>
      )}
    </VStack>
  );
}
