import React, { createRef, useState } from 'react';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '../theme';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';

import { useStoreState } from 'pullstate';
import { AppStateStore } from '../stores/characterStore';

export function SignInModal({
  isOpen,
  onClose,
  closeOnOverlayClick = true,
  data,
  onSubmit = () => {},
  ...props
}) {
  const initialRef = createRef();
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const primaryColor = useColorModeValue('accent.500', 'accent.200');
  const secondaryColor = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');
  const { isLoading, error } = useStoreState(AppStateStore, s => ({
    isLoading: s.isLoading,
    error: s.error,
  }));
  const [tempName, setTempName] = useState(data?.name || '');
  const [tempChannel, setTempChannel] = useState(data?.channel || '');
  const [create, setCreate] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(tempChannel, tempName, create);
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      onClose={onClose}
      size="xl"
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay sx={{ backdropFilter: 'blur(2px)' }} />
      <ModalContent color={textColor}>
        <Flex>
          <ModalHeader flex="1" textStyle="heading" className="accentWithHover">
            Sign In
          </ModalHeader>
          <Spacer />
          <ColorModeSwitcher m={2} color={primaryColor} />
        </Flex>

        <ModalBody>
          <form onSubmit={handleSubmit}>
            <Center>
              <VStack spacing={5} w="300px" mb={8}>
                <Box w="full">
                  <FormControl id="channel">
                    <FormLabel color={primaryColor}>Channel</FormLabel>
                    <Input
                      type="text"
                      rounded="lg"
                      variant="filled"
                      _focus={{
                        boxShadow: `0 0 0 3px ${transparentize(
                          'accent.500',
                          0.6
                        )(theme)}`,
                      }}
                      value={tempChannel}
                      onChange={e => setTempChannel(e.target.value)}
                      isRequired
                    />
                    <FormHelperText color={secondaryColor}>
                      Group name, campaign name, etc.
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box w="full">
                  <FormControl id="character name">
                    <FormLabel color={primaryColor}>Character Name</FormLabel>
                    <Input
                      ref={initialRef}
                      type="text"
                      rounded="lg"
                      variant="filled"
                      _focus={{
                        boxShadow: `0 0 0 3px ${transparentize(
                          'accent.500',
                          0.6
                        )(theme)}`,
                      }}
                      value={tempName}
                      onChange={e => setTempName(e.target.value)}
                      isRequired
                    />
                    <FormHelperText color={secondaryColor}>
                      Your character's fabulous name!
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Button
                  type="submit"
                  m={5}
                  colorScheme={error ? 'gray' : 'accent'}
                  isLoading={isLoading}
                >
                  Sign In
                </Button>
                <FormControl isInvalid={error}>
                  <FormErrorMessage>{error}</FormErrorMessage>
                </FormControl>
                {error && (
                  <Button
                    type="submit"
                    m={5}
                    colorScheme="accent"
                    isLoading={isLoading}
                    onClick={() => setCreate(true)}
                  >
                    Create New Character
                  </Button>
                )}
              </VStack>
            </Center>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
