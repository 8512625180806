import React from 'react';
import { Box, Center, Checkbox, Stack } from '@chakra-ui/react';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import { GameplayStore } from '../stores/gameplayStore';

export function Advances(props) {
  const [firstAdvances, secondAdvancements] = useStoreState(
    GameplayStore,
    s => s.advances
  );
  const charAdvances = useStoreState(CharacterStore, s => s.advances);

  const AdvanceCheckbox = (key, isChecked, text) => (
    <Checkbox
      key={key}
      isChecked={isChecked}
      colorScheme="accent"
      onChange={e => {
        CharacterStore.update(s => {
          s.advances = s.advances ?? [];
          if (e.target.checked) {
            s.advances.includes(key) || s.advances.push(key);
          } else {
            s.advances = s.advances.filter(item => item !== key);
          }
        });
      }}
    >
      <Box fontSize="sm">{text}</Box>
    </Checkbox>
  );

  return (
    <Center {...props}>
      <Stack spacing={5}>
        <Stack>
          {Object.entries(firstAdvances).map(([key, text]) =>
            AdvanceCheckbox(key, charAdvances?.includes(key), text)
          )}
        </Stack>
        <Stack>
          {Object.entries(secondAdvancements).map(([key, text]) =>
            AdvanceCheckbox(key, charAdvances?.includes(key), text)
          )}
        </Stack>
      </Stack>
    </Center>
  );
}
