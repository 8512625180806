import { Box, Heading, useColorModeValue } from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

export function Die({ dieClass, result }) {
  const bgColor = useColorModeValue(
    'accent.500',
    transparentize('accent.200', 0.16)
  );
  const color = useColorModeValue('white', 'accent.200');

  const twoRandomNumbers = Array.from(Array(2)).map(
    _ => Math.floor(Math.random() * 6) + 1
  );

  return (
    <Box
      className={`die-${dieClass}`}
      rounded="lg"
      color={color}
      bg={bgColor}
      p={3}
      w="70px"
    >
      <Heading
        aria-label={result}
        className={`die-number-${dieClass}`}
        data-char-1={twoRandomNumbers[0]}
        data-char-2={twoRandomNumbers[1]}
        data-char-3={result}
        textAlign="center"
        size="xl"
      ></Heading>
    </Box>
  );
}
