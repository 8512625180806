import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';

import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { GMModeSwitcher } from '../components/GMModeSwitcher';
import { License } from '../components/License';
import { ContentAccordion, HeaderAccordion } from '../hocs/ContentAccordion';

export function SingleCharacterView() {
  return (
    <Flex flexDir="column" alignItems="center" minH="100vh" p={3}>
      <Stack
        direction={{ base: 'column-reverse', md: 'row' }}
        alignItems="center"
        position="absolute"
        right="0"
        top="0"
        spacing={0}
      >
        <GMModeSwitcher
          _hover={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
        />
        <ColorModeSwitcher
          _hover={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
        />
      </Stack>
      <HeaderAccordion />
      <Box rounded="lg" p={5} flex="1" w="full" layerStyle="base">
        <ContentAccordion />
      </Box>
      <Box mt={1} p={2} alignSelf="end">
        <License linkVariant="tealAccent" />
      </Box>
    </Flex>
  );
}
