import { extendTheme } from '@chakra-ui/react';
import { transparentize, mode } from '@chakra-ui/theme-tools';
import './theme.css';

/* imported fonts:
 *  Rock Salt
 *  Raleway
 */

const accent = {
  50: '#ffe1ea',
  100: '#ffb1c1',
  200: '#ff7e9b',
  300: '#ff4c79',
  400: '#ff1a5a',
  500: '#e60048',
  600: '#b4002c',
  700: '#820016',
  800: '#500007',
  900: '#210100',
};

const pink = {
  50: '#ffe3ed',
  100: '#ffb3c7',
  200: '#fc839d',
  300: '#f95371',
  400: '#f62456',
  500: '#dd0d4a',
  600: '#ac0645',
  700: '#7c023a',
  800: '#4c0027',
  900: '#1f0010',
};

const brightPink = {
  50: '#ffe1f5',
  100: '#ffb1da',
  200: '#ff7ec1',
  300: '#ff4ca6',
  400: '#ff1a8d',
  500: '#e60073',
  600: '#b4005a',
  700: '#820040',
  800: '#500026',
  900: '#20000f',
};

const purple = {
  50: '#f8eaff',
  100: '#e1c6ee',
  200: '#caa1df',
  300: '#b37dd0',
  400: '#9e57c1',
  500: '#843ea8',
  600: '#673083',
  700: '#4a215e',
  800: '#2d133b',
  900: '#130419',
};

const brightPurple = {
  50: '#f5e8ff',
  100: '#dabef6',
  200: '#c094ec',
  300: '#a66ae2',
  400: '#8c3fd9',
  500: '#7326c0',
  600: '#591d96',
  700: '#40146c',
  800: '#260b43',
  900: '#10021b',
};

/*
#fb6674
#7e3ba0
*/

export const accentWithHover = props => ({
  color: mode('accentLight', 'accentDark')(props),
  _hover: {
    color: mode(`accent.400`, `accent.200`)(props),
    transition: 'color 100ms;',
  },
});

const moveCardTransitions = {
  '.my-node-enter': {
    opacity: 0,
    transform: 'translate(0, 30%)',
  },
  '.my-node-enter-active': {
    opacity: 1,
    transform: 'translate(0, 0)',
    transition: 'all 2000ms',
  },
  '.my-node-exit': {
    opacity: 1,
  },
  '.my-node-exit-active': {
    opacity: 0,
    transform: 'translate(0, 30%)',
    transition: 'all 2000ms',
  },
};

const moveStyles = props => ({
  '.trigger-text': {
    fontWeight: 'bold',
  },
  '.roll-stat': {
    fontWeight: 'bold',
    letterSpacing: '0.02rem',
    ...accentWithHover(props),
  },
  '.roll-stat::before': {
    content: `"♥"`,
    margin: '2px',
  },
  '.list-header': {
    fontWeight: 'bold',
    display: 'block',
    marginTop: '0.5rem',
  },
  '.move-ref': {
    fontWeight: 'bold',
    fontStyle: 'italic',
    _hover: {
      color: mode(`accent.500`, `accent.200`)(props),
      transition: 'color 300ms;',
    },
  },
  '.indent': {
    display: 'inline-block',
    width: '1rem',
  },
});

const gradientBGLight = props => ({
  position: 'relative',
  color: mode('white', 'whiteAlpha.900')(props),
  bgGradient: 'linear(to-b, pink.300, purple.500)',
  zIndex: 1,
});

// should be ::before to whatever gradientBGLight is applied to
const gradientBGDark = props => ({
  position: 'fixed',
  content: `""`,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  bgGradient: 'linear(to-b, pink.600, purple.700)',
  zIndex: -1,
  transition: 'opacity 0.2s ease-out',
  opacity: mode(0, 1)(props),
});

export const theme = extendTheme({
  styles: {
    global: props => ({
      'body, html': {
        minHeight: '100%',
        width: '100%',
        margin: 0,
      },
      body: {
        fontFamily: 'Raleway',
        letterSpacing: '0.001rem',
        ...gradientBGLight(props),
      },
      'body::before': { ...gradientBGDark(props) },
      '.accentWithHover': {
        ...accentWithHover(props),
      },
      'ol, ul': {
        paddingInlineStart: '20px',
        marginBottom: '0.5rem',
      },
      ul: {
        listStyleType: 'none',
      },
      'ul li::before': {
        content: `"+ "`,
        color: mode('accentLight', 'accentDark')(props),
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
        marginLeft: '-1em',
      },
      'li.chakra-toast::before': {
        content: `""`,
      },
      ...moveCardTransitions,
      ...moveStyles(props),
    }),
  },
  colors: {
    accent: accent,
    accentLight: accent[500],
    accentDark: accent[300],
    pink: pink,
    purple: purple,
  },
  layerStyles: {
    base: {
      color: 'gray.800',
      bg: 'white',
      '.chakra-ui-dark &': { color: 'whiteAlpha.900', bg: 'gray.800' },
    },
  },
  textStyles: {
    heading: {
      fontFamily: 'Rock Salt',
      lineHeight: 1.5,
      fontWeight: 'normal',
    },
  },
  components: {
    Link: {
      baseStyle: ({ colorScheme = 'accent', ...props }) => ({
        color: mode(`${colorScheme}.500`, `${colorScheme}.300`)(props),
        transition: 'color 50ms;',
        _hover: {
          color: mode(`${colorScheme}.400`, `${colorScheme}.200`)(props),
          transition: 'color 50ms;',
        },
      }),
      variants: {
        shadow: ({ colorScheme = 'accent', ...props }) => ({
          color: mode(`${colorScheme}.300`, `${colorScheme}.300`)(props),
          textShadow: '0px 1px 6px #40146c', //purple.700
          _hover: {
            color: mode(`${colorScheme}.200`, `${colorScheme}.200`)(props),
            transition: 'color 50ms;',
          },
        }),
        tealAccent: props => ({
          color: mode('teal.300', 'accent.300')(props),
          textShadow: '0px 1px 6px #40146c', //purple.700
          _hover: {
            color: mode('teal.200', 'accent.200')(props),
            transition: 'color 50ms;',
          },
        }),
      },
    },
    Button: {
      variants: {
        subtle: ({ colorScheme: c, theme, ...props }) => {
          if (c === 'gray') {
            return {
              color: mode(`inherit`, `whiteAlpha.900`)(props),
              bg: mode(`gray.100`, `whiteAlpha.200`)(props),
              _hover: {
                bg: mode(`gray.200`, `whiteAlpha.300`)(props),
              },
              _active: {
                bg: mode(`gray.300`, `whiteAlpha.300`)(props),
              },
            };
          }
          const darkBg = transparentize(`${c}.200`, 0.12)(theme);
          const darkHoverBg = transparentize(`${c}.200`, 0.18)(theme);
          const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);
          return {
            color: mode(`${c}.600`, `${c}.200`)(props),
            bg: mode(`${c}.50`, darkBg)(props),
            _hover: {
              bg: mode(`${c}.100`, darkHoverBg)(props),
            },
            _active: {
              bg: mode(`${c}.200`, darkActiveBg)(props),
            },
          };
        },
      },
    },
    Checkbox: {
      variants: {
        ghost: ({ colorScheme: c, ...props }) => ({
          control: {
            transition: 'box-shadow 250ms',
            border: '0px',
            color: 'currentColor',
            bg: 'transparent',

            _checked: {
              bg: 'transparent',
              color: 'currentColor',

              _hover: {
                bg: 'transparent',
                borderWidth: '2px',
                borderColor: mode(`${c}.600`, `${c}.300`)(props),
                transform: 'scale(1.1)',
                transition: 'transform 200ms',
              },

              _disabled: {
                bg: 'transparent',
                borderColor: mode('gray.200', 'transparent')(props),
                color: mode('gray.500', 'whiteAlpha.500')(props),
              },
            },

            _indeterminate: {
              borderColor: mode(`${c}.500`, `${c}.200`)(props),
              color: mode('white', 'gray.900')(props),
            },

            _disabled: {
              borderColor: mode('gray.100', 'transparent')(props),
            },

            _focus: {
              boxShadow: `0 0 0 3px whiteAlpha.900`,
            },

            _invalid: {
              borderColor: mode('red.500', 'red.300')(props),
            },
          },
          label: {
            _hover: {
              transform: 'scale(1.02)',
              transition: 'transform 200ms',
            },
          },
        }),
      },
    },
  },
});
