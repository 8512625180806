import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';

import { ConditionIcon } from '../hocs/Conditions';
import { RollModal } from '../hocs/Roll';

import { capitalize, toTitleCase } from '../functions/utils';

import { useStoreState } from 'pullstate';
import { GameplayStore } from '../stores/gameplayStore';

const ReactMarkdownWithHtml = require('react-markdown/with-html');

function GameText({ text, renderers: rends, ...rest }) {
  const renderers = rends || {
    paragraph: props => <Box {...props} {...rest} />,
    listItem: props => (
      <li style={{ fontSize: '0.875rem' }}>{props.children}</li>
    ),
  };

  return (
    <ReactMarkdownWithHtml
      children={text}
      renderers={renderers}
      allowDangerousHtml
    />
  );
}

GameText.defaultProps = {
  fontSize: 'sm',
};

const resultText = {
  up: '10+',
  mixed: '7–9',
  down: '6-',
};

export function ResultDescription({
  resultType,
  description,
  gmDescription = '',
  fontSize = 'sm',
}) {
  const defaultResult = useStoreState(
    GameplayStore,
    s => s.result?.[resultType]
  );
  const tagVariant = useColorModeValue('solid', 'subtle');
  const hover = useColorModeValue(
    {
      _hover: {
        bg: 'accent.400',
        transition: 'background-color 200ms;',
      },
    },
    {}
  );
  if (!description && !gmDescription & !defaultResult) {
    return null;
  }
  const tagText = resultText[resultType];
  if (!tagText) {
    return null;
  }

  return (
    <>
      <Box>
        <Tag
          colorScheme="accent"
          {...hover}
          borderRadius="full"
          variant={tagVariant}
        >
          <Text fontSize="xs" fontWeight="bold">
            {tagText}
          </Text>
        </Tag>{' '}
        <GameText
          text={description || defaultResult}
          fontSize={fontSize}
          d="inline"
        />
      </Box>
      {gmDescription && (
        <Box>
          <Tag colorScheme="purple" borderRadius="full" variant={tagVariant}>
            <Text fontSize="xs" fontWeight="bold">
              GM
            </Text>
          </Tag>{' '}
          <GameText text={gmDescription} fontSize={fontSize} d="inline" />
        </Box>
      )}
    </>
  );
}

export function ResultDescriptionTabs({
  move,
  result,
  isGMMode = false,
  playbook,
}) {
  const playbookResults = useStoreState(
    GameplayStore,
    s => s.playbooks?.[playbook]?.result,
    [playbook]
  );

  const selectedColor = useColorModeValue('white', 'accent.200');
  const selectedBGColor = useColorModeValue(
    'accent.500',
    transparentize('accent.200', 0.16)
  );
  const modColor = useColorModeValue('white', 'accent.200');
  const modBGColor = useColorModeValue(
    'accent.500',
    transparentize('accent.200', 0.16)
  );
  const [tabIndex, setTabIndex] = useState(
    ['down', 'mixed', 'up'].indexOf(result)
  );

  useEffect(() => {
    setTabIndex(['down', 'mixed', 'up'].indexOf(result));
  }, [result]);

  const tabs = ['down', 'mixed', 'up'].reduce((o, key, i) => {
    /*if (key === result) {
      setTabIndex(i);
    }*/
    return {
      ...o,
      [key]: (
        <ResultDescription
          resultType={key}
          description={move.result[key]}
          gmDescription={
            isGMMode
              ? [
                  move['gm result']?.[key],
                  playbookResults?.[key] &&
                    `<span class='list-header'>${toTitleCase(playbook)}</span>`,
                  playbookResults?.[key],
                ].join('')
              : undefined
          }
          fontSize="md"
        />
      ),
    };
  }, {});

  return (
    <Tabs
      isFitted
      w="100%"
      index={tabIndex}
      onChange={index => setTabIndex(index)}
      isLazy
    >
      <TabList border={0}>
        {Object.keys(tabs).map(key => (
          <Tab
            key={key}
            _selected={{
              color: selectedColor,
              bg: selectedBGColor,
              fontWeight: 500,
              rounded: 'lg',
            }}
          >
            <Heading size="sm" textStyle="heading">
              {capitalize(key)} Beat
            </Heading>
          </Tab>
        ))}
      </TabList>

      <TabPanels pt={1}>
        {Object.keys(tabs).map(key => (
          <TabPanel p={3} key={key}>
            <Stack>
              {tabs[key]}
              {key !== 'down' ? (
                <MoveOptions options={move?.result?.list} />
              ) : (
                <Box mb={3} />
              )}
              {move.modifiers?.moves?.map((mod, i) => (
                <React.Fragment key={mod.title || i}>
                  <Heading
                    key={`${mod.title || i}-heading`}
                    as="h3"
                    textStyle="heading"
                    size="xs"
                    rounded="lg"
                    width="fit-content"
                    px={2}
                    py={1}
                    color={modColor}
                    bgColor={modBGColor}
                  >
                    {mod.title}
                  </Heading>
                  <MoveDescription
                    key={`${mod.title || i}-contents`}
                    move={mod}
                  />
                </React.Fragment>
              ))}
            </Stack>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
}

export function MoveOptions({ options }) {
  if (!options) {
    return null;
  }
  return (
    <ul>
      {options.map((option, i) => (
        <li key={i} style={{ fontSize: '0.875rem' }}>
          <GameText
            text={option}
            renderers={{
              paragraph: ({ children }) => {
                return children;
              },
            }}
          />
        </li>
      ))}
    </ul>
  );
}

function MoveDescription({ move }) {
  if (!move) {
    return null;
  }
  const { description, result } = move;

  return (
    <>
      <GameText text={description} />
      {result && (
        <Stack>
          {['up', 'mixed'].map(resultType => (
            <ResultDescription
              key={`${resultType}-beat result`}
              resultType={resultType}
              description={result[resultType]}
            />
          ))}
          <MoveOptions options={result.list} />
        </Stack>
      )}
    </>
  );
}

function MoveConditionModifiers({ conditions }) {
  const modConditionColor = useColorModeValue('white', 'purple.200');
  const modConditionBGColor = useColorModeValue(
    'purple.500',
    transparentize('purple.200', 0.16)
  );

  if (!conditions) {
    return null;
  }

  return (
    <>
      {conditions.map((mod, i) => (
        <React.Fragment key={mod.title || i}>
          <Heading
            key={`${mod.title || i}-heading`}
            as="h3"
            textStyle="heading"
            size="xs"
            rounded="lg"
            width="fit-content"
            px={2}
            py={1}
            color={modConditionColor}
            bgColor={modConditionBGColor}
          >
            <ConditionIcon mr={2} />
            {mod.title}
          </Heading>
          <MoveDescription key={`${mod.title || i}-contents`} move={mod} />
        </React.Fragment>
      ))}
    </>
  );
}

function MoveModifyingMoves({ moves }) {
  const modMoveColor = useColorModeValue('white', 'accent.200');
  const modMoveBGColor = useColorModeValue(
    'accent.500',
    transparentize('accent.200', 0.16)
  );

  if (!moves) {
    return null;
  }

  return (
    <>
      {moves.map((mod, i) => (
        <React.Fragment key={mod.title || i}>
          <Heading
            key={`${mod.title || i}-heading`}
            as="h3"
            textStyle="heading"
            size="xs"
            rounded="lg"
            width="fit-content"
            px={2}
            py={1}
            color={modMoveColor}
            bgColor={modMoveBGColor}
          >
            {mod.title}
          </Heading>
          <MoveDescription key={`${mod.title || i}-contents`} move={mod} />
        </React.Fragment>
      ))}
    </>
  );
}

export function MoveContents({ move }) {
  return (
    <>
      <MoveDescription move={move} />
      <MoveConditionModifiers conditions={move.modifiers?.conditions} />
      <MoveModifyingMoves moves={move.modifiers?.moves} />
    </>
  );
}

export const MoveCard = React.forwardRef(({ move, ...props }, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const shadowColor = useColorModeValue('0, 0, 0', '255, 255, 255');

  const rollButtonVariant = useColorModeValue('solid', 'subtle');
  const [showButton, setShowButton] = useState(false);

  if (!move) {
    return null;
  }

  return (
    <Stack
      ref={ref}
      orientation="column"
      rounded="lg"
      p={4}
      maxWidth="500px"
      spacing={2}
      sx={{
        boxShadow: `0 4px 8px 0 rgba(${shadowColor}, 0.2), 0 6px 20px 0 rgba(${shadowColor}, 0.19)`,
      }}
      tabIndex={0}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      onFocus={() => setShowButton(true)}
      onBlur={() => setShowButton(false)}
      {...props}
    >
      <Heading textStyle="heading" size="sm" className="accentWithHover">
        {move.title}
      </Heading>
      <MoveContents move={move} />

      {move.stats && !move.modifies && (
        <>
          <Button
            colorScheme="purple"
            variant={rollButtonVariant}
            visibility={showButton ? '' : 'hidden'}
            w="100%"
            onClick={onOpen}
          >
            <Text textStyle="heading" as="i">
              Roll it!
            </Text>
          </Button>
          <RollModal move={move} isOpen={isOpen} onClose={onClose} />
        </>
      )}
    </Stack>
  );
});
