import { Flex, Heading, useColorModeValue } from '@chakra-ui/react';

export function RollResult({ result }) {
  const color = useColorModeValue('purple.600', 'purple.200');

  let rollResultClassName;

  if (result < 7) {
    // Down Beat
    rollResultClassName = 'roll-result-down-beat';
  } else if (result < 10) {
    // Mixed Beat
    rollResultClassName = 'roll-result-mixed-beat';
  } else {
    // Up Beat
    rollResultClassName = 'roll-result-up-beat';
  }

  const resultChars = result.toString().split('');

  return (
    <Flex justify="center">
      <Heading
        aria-label={result}
        data-test-id="roll-result"
        display="flex"
        color={color}
        fontSize="4rem"
        textAlign="center"
        marginBottom="1rem"
      >
        {resultChars.map((char, i) => (
          <span
            aria-hidden="true"
            className={rollResultClassName}
            style={{
              // delay in reverse order, ie first char animates last
              animationDelay: `${0.05 * (resultChars.length - 1 - i)}s`,
            }}
          >
            {char}
          </span>
        ))}
      </Heading>
    </Flex>
  );
}
