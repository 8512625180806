export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export function capitalize(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

export function toTitleCase(string) {
  return string
    ? string.replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      )
    : '';
}

function getRandomInt(min, max) {
  min = Math.ceil(min); // Min is inclusive
  max = Math.floor(max + 1); // Max is exclusive, so add 1
  return Math.floor(Math.random() * (max - min) + min);
}

export function roll(modifiers = [], caption) {
  const a = getRandomInt(1, 6);
  const b = getRandomInt(1, 6);
  const roll = [a, b, ...modifiers];
  const total = roll.reduce((a, b) => a + b, 0);
  const result = total <= 6 ? 'down' : total > 9 ? 'up' : 'mixed';
  return {
    ...{ total: total, roll: roll, result: result },
    ...(caption && { caption: caption }),
  };
}
