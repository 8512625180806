import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { GiHeartKey, GiLockedHeart } from 'react-icons/gi';

import { Tooltip } from './Tooltip';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';

export const GMModeSwitcher = props => {
  const gmMode = useStoreState(CharacterStore, s => s.GM);
  const toggleGMMode = () =>
    CharacterStore.update(s => {
      s.GM = !s.GM;
    });
  const text = gmMode ? 'Hide' : 'Unlock';
  const SwitchIcon = gmMode ? GiLockedHeart : GiHeartKey;
  const label = `${text} GM info`;

  return (
    <Tooltip label={label}>
      <IconButton
        size="md"
        fontSize="lg"
        aria-label={label}
        variant="ghost"
        color="current"
        onClick={toggleGMMode}
        icon={<SwitchIcon />}
        {...props}
      />
    </Tooltip>
  );
};
