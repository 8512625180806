import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { CheckboxStack } from '../../components/CheckboxStack';
import { HiddenContent } from '../../components/HiddenContent';
import { uuidv4 } from '../../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';

const ReactMarkdownWithHtml = require('react-markdown/with-html');

export function ChosenSetup(props) {
  return <ChosenControls withDescription {...props} />;
}

export function ChosenControls({ withDescription = false, ...props }) {
  const move = useStoreState(GameplayStore, s => s.moves['destiny']);
  const aspects = useStoreState(GameplayStore, s => s.playbooks.chosen.aspects);
  const charDestiny = useStoreState(CharacterStore, s => s.stats?.destiny);
  const charAspects = useStoreState(CharacterStore, s => s.stats?.aspects);
  const aspectsToObjects = (aspects, type) =>
    aspects.reduce(
      (o, val) => [
        ...o,
        {
          text: val,
          type: type,
          checked: charAspects?.some(item => item.text === val),
        },
      ],
      []
    );
  const heroic = aspectsToObjects(aspects.heroic, 'heroic');
  const tragic = aspectsToObjects(aspects.tragic, 'tragic');
  const [tempDestiny, setTempDestiny] = useState(null);

  function handleChange(val, isChecked) {
    CharacterStore.update(s => {
      s.stats ?? (s.stats = {});
      s.stats.aspects ?? (s.stats.aspects = []);
      if (isChecked) {
        s.stats.aspects.some(item => item.id === val.id) ||
          s.stats.aspects.push({
            id: uuidv4(),
            text: val.text,
            type: val.type,
            checked: false,
            resolved: false,
          });
      } else {
        s.stats.aspects = s.stats.aspects.filter(
          item => item.text !== val.text
        );
      }
    });
  }

  return (
    <Stack fontSize="sm" {...props}>
      <Box>
        They keep telling you that you have a Destiny, but it’s not what your
        heart truly desires.
        <Editable
          placeholder="What is it?"
          textAlign="center"
          value={tempDestiny ?? charDestiny}
          onChange={val => setTempDestiny(val)}
          onSubmit={val => {
            CharacterStore.update(s => {
              s.stats ?? (s.stats = {});
              s.stats.destiny = val;
            });
            setTempDestiny(null);
          }}
        >
          <EditablePreview />
          <EditableInput />
        </Editable>
      </Box>
      <Box>
        <Box>Choose two Heroic Aspects and two Tragic Aspects:</Box>
        <SimpleGrid fontSize=".875rem" columns={2} spacing={2} my={3}>
          <CheckboxStack
            heading="Heroic"
            options={heroic}
            onChange={(val, e) => handleChange(val, e.target.checked)}
          />
          <CheckboxStack
            heading="Tragic"
            options={tragic}
            onChange={(val, e) => handleChange(val, e.target.checked)}
          />
        </SimpleGrid>
        {withDescription && (
          <ReactMarkdownWithHtml
            children={move.description}
            allowDangerousHtml
          />
        )}
      </Box>
    </Stack>
  );
}

function AspectResolve({ val, children, onChange, props }) {
  return (
    <HStack {...props}>
      <Text as="i" mr={2}>
        {children}
      </Text>
      <Checkbox
        isChecked={val.selected}
        size="sm"
        colorScheme="accent"
        onChange={e => onChange(val, e)}
      />
    </HStack>
  );
}

export function ChosenStats(props) {
  const destiny = useStoreState(CharacterStore, s => s?.stats?.destiny) || '';
  const aspects = useStoreState(CharacterStore, s => s?.stats?.aspects) || [];

  const heroic = aspects.reduce(
    (o, val) => (val.type === 'heroic' ? [...o, val] : [...o]),
    []
  );
  const tragic = aspects.reduce(
    (o, val) => (val.type === 'tragic' ? [...o, val] : [...o]),
    []
  );
  if (!heroic && !tragic) {
    return null;
  }

  function handleChange(val, attr, isChecked) {
    CharacterStore.update(s => {
      s.stats ?? (s.stats = {});
      s.stats.aspects ?? (s.stats.aspects = []);
      s.stats.aspects = s.stats.aspects.map(item => {
        if (item.id === val.id) {
          item[attr] = isChecked;
        }
        if (item.checked === false) {
          item.resolved = false;
        }
        return item;
      });
    });
  }

  const hiddenContent = [
    [
      ...heroic
        .filter(item => item.checked && !item.resolved)
        .map((item, i) => (
          <AspectResolve
            key={`heroic-${i}`}
            val={item}
            onChange={(val, e) =>
              handleChange(val, 'resolved', e.target.checked)
            }
          >
            Take +1 forward
          </AspectResolve>
        )),
    ],
    [
      ...tragic
        .filter(item => item.checked && !item.resolved)
        .map((item, i) => (
          <AspectResolve
            key={`tragic-${i}`}
            val={item}
            onChange={(val, e) =>
              handleChange(val, 'resolved', e.target.checked)
            }
          >
            Take +1 forward and mark XP
          </AspectResolve>
        )),
    ],
  ].filter(item => item.length);

  return (
    <VStack spacing={2}>
      {destiny && (
        <Heading
          textStyle="heading"
          as="i"
          lineHeight={1.2}
          fontSize={{ base: 'md', md: 'lg' }}
        >
          {destiny}
        </Heading>
      )}
      {heroic.every(item => item.checked) &&
        tragic.every(item => item.checked) && (
          <Heading
            textStyle="heading"
            as="i"
            lineHeight={1.2}
            fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
            margin={2}
          >
            Your Destiny grows nearer ...
          </Heading>
        )}
      <HiddenContent
        visible={
          <SimpleGrid fontSize=".875rem" columns={2} spacing={2}>
            {heroic.length > 0 && (
              <CheckboxStack
                heading="Heroic"
                options={heroic}
                onChange={(val, e) =>
                  handleChange(val, 'checked', e.target.checked)
                }
                crossOut
              />
            )}
            {tragic.length > 0 && (
              <CheckboxStack
                heading="Tragic"
                options={tragic}
                onChange={(val, e) =>
                  handleChange(val, 'checked', e.target.checked)
                }
                crossOut
              />
            )}
          </SimpleGrid>
        }
        hidden={
          hiddenContent && (
            <VStack mx={2}>
              {hiddenContent.map((child, i) => (
                <Box key={i}>{child}</Box>
              ))}
            </VStack>
          )
        }
        showHidden={hiddenContent.length > 0}
      />
    </VStack>
  );
}
