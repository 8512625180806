import React from 'react';
import { Tooltip as ChakraTooltip, useColorModeValue } from '@chakra-ui/react';

export function Tooltip({ label, children, ...props }) {
  const color = useColorModeValue('gray.800', 'whiteAlpha.900');
  const bg = useColorModeValue('white', 'gray.800');

  return (
    <ChakraTooltip
      hasArrow
      isDisabled={!label}
      rounded="lg"
      label={label}
      openDelay={500}
      p={2}
      color={color}
      bg={bg}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
}
