import React, { useState } from 'react';
import {
  Button,
  Center,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spacer,
  VStack,
  useColorModeValue,
  useDisclosure,
  HStack,
  Heading,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/ColorModeSwitcher';

import { useStoreState } from 'pullstate';
import { GameplayStore, getAdvanceableMoves } from '../stores/gameplayStore';
import { CharacterStore } from '../stores/characterStore';
import { toTitleCase } from '../functions/utils';

export function PlaybookSelect(props) {
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const playbooks = useStoreState(GameplayStore, s => s.playbooks);

  const [selectedPlaybook, setSelectedPlaybook] = useState(playbook);

  function handleChange(val) {
    setSelectedPlaybook(val);
    CharacterStore.update(s => {
      s.playbook = val.toLowerCase();
    });
  }

  return (
    <Select
      value={selectedPlaybook}
      onChange={e => {
        handleChange(e.target.value);
      }}
      w="max-content"
      {...props}
    >
      {Object.keys(playbooks).map(val => (
        <option key={val} value={val}>
          {toTitleCase(val)}
        </option>
      ))}
    </Select>
  );
}

function StatBlock({ name, value, onChange, min, max, prefix = '', ...props }) {
  const format = val => (val > 0 ? prefix + val : val);
  const parse = val => parseInt(val.replace(/^\${prefix}/, ''));

  return (
    <VStack {...props}>
      <NumberInput
        size="sm"
        onChange={valueString => onChange(name, parse(valueString))}
        value={format(value)}
        min={min}
        max={max}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Heading size="sm">{name}</Heading>
    </VStack>
  );
}

export function CharacterSettings({
  name,
  channel,
  primaryColor,
  secondaryColor,
  ...props
}) {
  const gameStats = useStoreState(GameplayStore, s => s.stats);
  const charStats = useStoreState(CharacterStore, s => s.stats);
  const stats = {
    // [stat1, stat2, stat3] => {stat1: x, stat2: y, stat3: z}
    ...Object.keys(gameStats)?.reduce(
      (o, key) => ({ ...o, [key]: charStats?.[key] ?? 0 }),
      {}
    ),
  };
  const extraStats = Object.keys(charStats)
    .filter(k => !Object.keys(gameStats).includes(k))
    .reduce((o, key) => ({ ...o, [key]: charStats[key] }), {});
  const xpValue = useStoreState(CharacterStore, s => s.xp);
  const gameConditions = useStoreState(GameplayStore, s => s.conditions);
  const charConditions = useStoreState(CharacterStore, s => s.conditions);
  const moves = useStoreState(GameplayStore, s => s.moves);
  const playbookMoves = getAdvanceableMoves(moves);
  const charMoves = useStoreState(CharacterStore, s => s.moves);

  return (
    <Center>
      <VStack spacing={5} mb={8}>
        <HStack mx={2} spacing={3}>
          {Object.entries(stats).map(([key, value]) => (
            <StatBlock
              name={key}
              value={value}
              prefix={'+'}
              min={-3}
              max={3}
              onChange={(name, value) => {
                CharacterStore.update(s => {
                  s.stats[name] = value;
                });
              }}
            />
          ))}
        </HStack>
        <HStack mx={2} spacing={3}>
          {Object.entries(extraStats).map(([key, value]) => (
            <StatBlock
              key={key}
              name={key}
              value={value}
              min={0}
              max={4}
              onChange={(name, value) => {
                CharacterStore.update(s => {
                  s.stats[name] = value;
                });
              }}
            />
          ))}
        </HStack>
        <StatBlock
          name={'xp'}
          value={xpValue}
          onChange={(_, value) => {
            CharacterStore.update(s => {
              s.xp = value;
            });
          }}
        />
        <PlaybookSelect />
        <HStack>
          {Object.keys(gameConditions).map(name => (
            <Checkbox
              key={name}
              isChecked={charConditions?.[name]}
              onChange={e => {
                CharacterStore.update(s => {
                  s.conditions = s.conditions ?? {};
                  s.conditions[name] = e.target.checked;
                });
              }}
            >
              {name}
            </Checkbox>
          ))}
        </HStack>
        <VStack>
          {Object.entries(playbookMoves).map(([key, { title }]) => (
            <Checkbox
              key={key}
              isChecked={charMoves?.includes(key)}
              onChange={e => {
                CharacterStore.update(s => {
                  s.moves = s.moves ?? [];
                  if (e.target.checked) {
                    s.moves.includes(key) || s.moves.push(key);
                  } else {
                    s.moves = s.moves.filter(item => item !== key);
                  }
                });
              }}
            >
              {title}
            </Checkbox>
          ))}
        </VStack>
      </VStack>
    </Center>
  );
}

export function CharacterSettingsModal({
  isOpen,
  onClose,
  closeOnOverlayClick = true,
  error,
  ...props
}) {
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const primaryColor = useColorModeValue('accent.500', 'accent.200');
  const secondaryColor = useColorModeValue('blackAlpha.700', 'whiteAlpha.700');

  return (
    <Modal
      onClose={onClose}
      size="xl"
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay sx={{ backdropFilter: 'blur(2px)' }} />
      <ModalContent color={textColor}>
        <Flex>
          <ModalHeader
            flex="1"
            flexGrow="2"
            textStyle="heading"
            className="accentWithHover"
          >
            Character Settings
          </ModalHeader>
          <Spacer />
          <ColorModeSwitcher m={2} color={primaryColor} />
        </Flex>

        <ModalBody>
          <CharacterSettings
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            {...props}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export function CharacterSettingsButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen}>Character Settings</Button>
      <CharacterSettingsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
