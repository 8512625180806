import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Collapse,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

import { ColorModeSwitcher } from '../components/ColorModeSwitcher';
import { Die } from '../components/Die';
import { GMModeSwitcher } from '../components/GMModeSwitcher';
import { MoveContents, ResultDescriptionTabs } from '../components/MoveCard';
import { RadioGroup } from '../components/RadioGroup';
import { RollResult } from '../components/RollResult';
import { ConditionIcon } from './Conditions';
import { Modifer } from '../components/Modifier';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import { GameplayStore } from '../stores/gameplayStore';
import { addMessage } from '../stores/messageStore';

import { capitalize, roll as rollFunc } from '../functions/utils';

const buttonColor = 'purple';

function StatButtonContent({ name, value }) {
  return (
    <VStack spacing={0}>
      <Box sx={{ fontSize: '1rem' }}>{capitalize(name)}</Box>
      <Box>
        {value > 0 && '+'}
        {value}
      </Box>
    </VStack>
  );
}

function StatsRadioGroup({
  highlighted,
  selected,
  charStats,
  onChange = () => {},
}) {
  const gameStats = useStoreState(GameplayStore, s => s.stats);

  const stats = Object.keys(gameStats).reduce(
    (o, key) => ({
      ...o,
      [key]: {
        content: <StatButtonContent name={key} value={charStats?.[key] ?? 0} />,
        isHighlighted: highlighted.includes(key),
      },
    }),
    {}
  );

  return (
    <RadioGroup
      name="stats"
      options={stats}
      selected={selected}
      onChange={onChange}
      colorScheme="accent"
      variant="subtle"
      selectedColor="accent"
      py={2}
      height="fit-content"
    />
  );
}

function ConditionModifiers({ conditions }) {
  const conditionColor = useColorModeValue('purple.500', 'purple.200');

  if (!conditions) {
    return null;
  }

  return (
    <Stack alignItems="center">
      {Object.values(conditions).map(condition => (
        <HStack px={3} width="60%">
          <ConditionIcon size={6} mx={2} color={conditionColor} />
          <Heading
            textStyle="heading"
            lineHeight={1.2}
            color={conditionColor}
            fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
          >
            {condition.title}
          </Heading>
          <Spacer />
          <Heading
            lineHeight={1.2}
            fontWeight="normal"
            color={conditionColor}
            fontSize={{ base: 'sm', md: 'md', lg: 'lg' }}
          >
            {condition.modifier}
          </Heading>
        </HStack>
      ))}
    </Stack>
  );
}

function RollForm({ move, onRoll = () => {} }) {
  const charStats = useStoreState(CharacterStore, s => s.stats);

  const buttonVariant = useColorModeValue('solid', 'subtle');

  const defaultStat = (move?.stats.length === 1 && move.stats[0]) || null;
  const [stat, setStat] = useState(defaultStat);

  const conditions = move.modifiers?.conditions;
  const condModifier =
    conditions &&
    Object.keys(conditions)?.reduce(
      (o, key) => o + conditions[key].modifier,
      0
    );

  const [mod, setMod] = useState('');

  const [caption, setCaption] = useState('');

  function handleRoll() {
    var numbers = [parseInt(charStats?.[stat]) || 0];
    if (!isNaN(condModifier)) {
      numbers.push(condModifier);
    }
    let modInt = parseInt(mod);
    if (!isNaN(modInt)) {
      numbers.push(modInt);
    }
    onRoll(rollFunc(numbers, caption));
  }

  return (
    <Stack orientation="column" spacing={7}>
      <Box mt={4}>
        <Heading flexGrow="2" size="md" textStyle="heading" mb={2}>
          Roll with:
        </Heading>
        <Center>
          <StatsRadioGroup
            highlighted={move.stats}
            selected={stat}
            charStats={charStats}
            onChange={setStat}
          />
        </Center>
      </Box>
      <ConditionModifiers conditions={conditions} />
      <Center>
        <Accordion allowToggle width="80%">
          <AccordionItem border={0}>
            <AccordionButton rounded="md">
              <Heading size="sm" textStyle="heading">
                Optional: Additional Modifiers
              </Heading>
              <Spacer />
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <VStack>
                <Box>(More here later, sorry)</Box>
                <Input
                  lineHeight="2"
                  type="number"
                  maxW="50px"
                  value={mod}
                  onChange={e => setMod(e.target.value)}
                />
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Center>
      <Flex justify="center">
        <Input
          m={1}
          type="text"
          variant="outline"
          placeholder="Caption roll"
          rounded="lg"
          maxW="80%"
          value={caption}
          onChange={e => setCaption(e.target.value)}
        />
      </Flex>
      <Center>
        <Button
          onClick={handleRoll}
          isDisabled={!stat}
          textStyle="heading"
          colorScheme={buttonColor}
          variant={buttonVariant}
          width="fit-content"
          p={5}
        >
          Roll!
        </Button>
      </Center>
    </Stack>
  );
}

export function PreRollMove({ move, onRoll = () => {} }) {
  return (
    <Stack orientation="column" p={4} spacing={2}>
      <MoveContents move={move} />
      <RollForm move={move} onRoll={onRoll} />
    </Stack>
  );
}

export function PostRollMove({ move, roll }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
    setTimeout(function () {
      setShow(true);
    }, 1500);
  }, [roll]);

  const dice = roll.roll.slice(0, 2);
  const modifiers = roll.roll.slice(2).filter(m => m !== 0);

  return (
    <VStack orientation="column" pb={4} px={4} spacing={2}>
      <HStack data-test-id="dice-data">
        {dice.map((die, i) => (
          <Die dieClass={1} result={die} />
        ))}

        {modifiers.map((mod, i) => (
          <Modifer modifierClass={1} value={mod} />
        ))}
      </HStack>
      <Collapse in={show} animateOpacity>
        <RollResult result={roll.total} />
        <ResultDescriptionTabs move={move} result={roll?.result} />
      </Collapse>
    </VStack>
  );
}

export function RollModal({
  move,
  isOpen,
  onClose,
  closeOnOverlayClick = true,
  error,
  ...props
}) {
  const initialRef = React.useRef();
  const { name, gmMode } = useStoreState(CharacterStore, s => {
    return { name: s.name, gmMode: s.GM };
  });
  const [roll, setRoll] = useState(null);
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');

  if (!move) {
    return null;
  }

  function handleRoll(roll) {
    addMessage({ characterName: name, moveName: move.title, ...roll });
    setRoll(roll);
    console.log(roll);
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      onClose={onClose}
      size="xl"
      isOpen={isOpen}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />
      <ModalContent color={textColor}>
        <Flex>
          <ModalHeader
            flex="1"
            flexGrow="2"
            textStyle="heading"
            className="accentWithHover"
          >
            {move.title || ''}
          </ModalHeader>
          <Spacer />
          <Stack direction="row" spacing={0} alignItems="start" p={1}>
            <GMModeSwitcher
              _hover={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
            />
            <ColorModeSwitcher
              _hover={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
            />
          </Stack>
        </Flex>

        <ModalBody>
          {gmMode || roll !== null ? (
            <PostRollMove move={move} roll={roll} gmMode={gmMode} {...props} />
          ) : (
            <PreRollMove
              move={move}
              onRoll={r => {
                handleRoll(r);
              }}
              {...props}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {roll !== null && (
            <Button
              mr={2}
              onClick={() => {
                setRoll(null);
              }}
            >
              {`< Back`}
            </Button>
          )}
          <Button
            ref={initialRef}
            onClick={() => {
              setRoll(null);
              onClose();
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
