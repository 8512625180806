import React, { useState } from 'react';
import { Heading } from '@chakra-ui/react';

import { NumberTrack } from './NumberTrack';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function TragedyTrack({ value, max, onChange = () => {}, ...props }) {
  const [tempVal, setTempVal] = useState(null);

  const showTragedyStrikes =
    (tempVal || value) === max || (tempVal ?? value) === 0;

  return (
    <NumberTrack
      value={tempVal || value}
      label="Tragedy"
      onChange={val => {
        setTempVal(val);
        onChange(val);
      }}
      showHidden={showTragedyStrikes}
      {...props}
    >
      <Heading
        textStyle="heading"
        as="i"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        {(tempVal || value) === max ? 'A Memory Fades' : 'No Legion Powers'}
      </Heading>
    </NumberTrack>
  );
}

export function LegionStats(props) {
  const tragedy = useStoreState(CharacterStore, s => s?.stats?.tragedy);
  if (tragedy === null) {
    return null;
  }
  const max = 4;

  return (
    <TragedyTrack
      value={tragedy}
      max={max}
      onChange={val => {
        CharacterStore.update(s => {
          s.stats ?? (s.stats = {});
          s.stats.tragedy = val;
        });
      }}
      {...props}
    />
  );
}
