import React from 'react';
import { Box, Stack } from '@chakra-ui/react';

import { NumberTrack } from './NumberTrack';
import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function Tradition(props) {
  const tradition = useStoreState(CharacterStore, s => s?.stats?.tradition);
  if (tradition === null) {
    return null;
  }
  const max = 4;
  const showMsg = tradition === max;

  return (
    <NumberTrack
      value={tradition}
      label="Tradition"
      onChange={val => {
        CharacterStore.update(s => {
          s.stats ?? (s.stats = {});
          s.stats.tradition = val;
        });
      }}
      showHidden={showMsg}
      {...props}
    >
      <Box maxWidth="200px">
        Incur a Condition each time you act contrary to your Commandments
      </Box>
    </NumberTrack>
  );
}

export function Commandments(props) {
  //const commandments = useStoreState(CharacterStore, s => s?.stats?.commandments);
  const commandments = [
    'Always answer an insult with a drawn sword',
    'Never admit to your weak emotions',
    'Always cover your sinful body',
    'No intimacy until after a monogamous marriage',
    'Never give when you can sell',
    'Never let a weaker person fight their own battles',
    'Never fight a weaker person’s battles',
    'Always obey the Authority',
    'Always obey a certain type of person (gender, race, class, belief, elders) ',
    'Never go unchaperoned (or at all) with a certain type of person',
  ];
  if (commandments === null) {
    return null;
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const n = getRandomInt(6, commandments.length);

  return (
    <Box fontSize=".875rem" minWidth="min(max-content, 300px)">
      <ul>
        {commandments
          .sort(() => 0.5 - Math.random())
          .slice(0, n)
          .map((val, i) => (
            <li key={i}>{val}</li>
          ))}
      </ul>
    </Box>
  );
}

export function SeekerStats(props) {
  return (
    <Stack direction={{ base: 'column', md: 'row' }}>
      <Commandments {...props} />
      <Tradition {...props} />
    </Stack>
  );
}
