import React from 'react';
import { Box, Collapse } from '@chakra-ui/react';

import { GridFlex } from './GridFlex';

export function HiddenContent({ visible, hidden, showHidden, ...props }) {
  return (
    <GridFlex {...props}>
      {visible}
      <Collapse in={showHidden} animateOpacity>
        <Box display={!showHidden && 'none'}>{hidden}</Box>
      </Collapse>
    </GridFlex>
  );
}
