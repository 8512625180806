import React, { useState } from 'react';
import { Heading } from '@chakra-ui/react';

import { NumberTrack } from './NumberTrack';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function NagamaniTrack({
  value,
  hasCondition,
  max,
  onChange = () => {},
  ...props
}) {
  const [tempVal, setTempVal] = useState(null);

  const showVenom =
    (hasCondition && (tempVal || value) === max) || (tempVal ?? value) === 0;

  return (
    <NumberTrack
      value={tempVal || value}
      label="Nagamani"
      onChange={val => {
        setTempVal(val);
        onChange(val);
      }}
      showHidden={showVenom}
      {...props}
    >
      <Heading
        textStyle="heading"
        as="i"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        {(tempVal || value) === max ? 'Unleash Your Venom!' : 'No Naga Powers'}
      </Heading>
    </NumberTrack>
  );
}

export function NagaStats(props) {
  const nagamani = useStoreState(CharacterStore, s => s?.stats?.nagamani);
  const conditions = useStoreState(CharacterStore, s => s.conditions);
  const anyCondition = Object.values(conditions).some(x => x);
  if (nagamani === null) {
    return null;
  }
  const max = 4;

  return (
    <NagamaniTrack
      value={nagamani}
      max={max}
      hasCondition={anyCondition}
      onChange={val => {
        CharacterStore.update(s => {
          s.stats ?? (s.stats = {});
          s.stats.nagamani = val;
        });
      }}
      {...props}
    />
  );
}
