import React from 'react';
import {
  Box,
  Checkbox,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { HiddenContent } from '../../components/HiddenContent';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';
import { GameplayStore } from '../../stores/gameplayStore';

const ReactMarkdownWithHtml = require('react-markdown/with-html');

function TrialsStack({
  heading,
  trials,
  onChange,
  columns = 2,
  showResolved = false,
  crossOutResolved = false,
  ...props
}) {
  if (!trials || trials.length === 0) {
    return null;
  }
  if (!showResolved) {
    trials = trials.filter(val => !val.resolved);
  }

  return (
    <VStack {...props}>
      <Heading
        textStyle="heading"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        {heading}
      </Heading>
      <Stack width="100%">
        {trials.map((val, i) => (
          <Checkbox
            key={i}
            isChecked={val.checked}
            size="sm"
            colorScheme="accent"
            onChange={e => onChange(val, e)}
          >
            <Text as={crossOutResolved && val.resolved && 's'}>{val.text}</Text>
          </Checkbox>
        ))}
      </Stack>
    </VStack>
  );
}

export function NatureWitchSetup(props) {
  return <NatureWitchControls withDescription {...props} />;
}

export function NatureWitchControls({ withDescription = false, ...props }) {
  const move = useStoreState(GameplayStore, s => s.moves['curiosity']);
  const trials = useStoreState(
    GameplayStore,
    s => s.playbooks['nature witch'].trials
  );
  const charTrials = useStoreState(CharacterStore, s => s.stats?.trials);
  const trialData = trials.reduce(
    (o, val) => {
      const newVal = {
        text: val,
        checked: charTrials?.some(item => item.text === val),
      };
      if (charTrials?.some(item => item.text === val && item.checked)) {
        return {
          options: o.options,
          completed: [...o.completed, newVal],
        };
      } else {
        return { options: [...o.options, newVal], completed: o.completed };
      }
    },
    { options: [], completed: [] }
  );

  function handleChange(val, isChecked) {
    CharacterStore.update(s => {
      s.stats ?? (s.stats = {});
      s.stats.trials ?? (s.stats.trials = []);
      if (isChecked) {
        s.stats.trials.some(item => item.text === val.text) ||
          s.stats.trials.push({
            text: val.text,
            checked: false,
            resolved: false,
          });
      } else {
        s.stats.trials = s.stats.trials.filter(item => item.text !== val.text);
      }
    });
  }

  return (
    <Stack fontSize="sm" {...props}>
      <Box>
        Dealing with people feels new to you and you might be awkward, but you
        want to learn. What better way to learn than by doing?
      </Box>
      <Box>
        <Box>Choose four Trials to begin with:</Box>
        <SimpleGrid fontSize=".875rem" columns={1} spacing={4} my={3}>
          <TrialsStack
            heading="Trials"
            trials={trialData.options}
            onChange={(val, e) => handleChange(val, e.target.checked)}
          />
          <TrialsStack
            heading="Completed Trials"
            trials={trialData.completed}
            onChange={(val, e) => handleChange(val, e.target.checked)}
          />
        </SimpleGrid>
        {withDescription && (
          <ReactMarkdownWithHtml
            children={move.description}
            allowDangerousHtml
          />
        )}
      </Box>
    </Stack>
  );
}

function TrialResolve({ val, children, onChange, props }) {
  return (
    <HStack {...props}>
      <Text as="i" mr={2}>
        {children}
      </Text>
      <Checkbox
        isChecked={val.selected}
        size="sm"
        colorScheme="accent"
        onChange={e => onChange(val, e)}
      />
    </HStack>
  );
}

export function NatureWitchStats(props) {
  const trials = useStoreState(CharacterStore, s => s?.stats?.trials) || [];

  function handleChange(val, attr, isChecked) {
    CharacterStore.update(s => {
      s.stats ?? (s.stats = {});
      s.stats.trials ?? (s.stats.trials = []);
      s.stats.trials = s.stats.trials.map(item => {
        if (item.text === val.text) {
          item[attr] = isChecked;
        }
        if (item.checked === false) {
          item.resolved = false;
        }
        return item;
      });
    });
  }

  const trialsCount = Object.keys(trials).length;

  const trialResolutions = [
    ...trials.map(
      (item, i) =>
        item.checked &&
        !item.resolved && (
          <TrialResolve
            key={`trial-${i}`}
            val={item}
            onChange={(val, e) =>
              handleChange(val, 'resolved', e.target.checked)
            }
          >
            Mark XP, clear a Condition, or take a String on someone involved
          </TrialResolve>
        )
    ),
  ].filter(item => item);

  return (
    <VStack>
      {!trialsCount && (
        <Heading
          textStyle="heading"
          as="i"
          lineHeight={1.2}
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
        >
          Choose your trials ...
        </Heading>
      )}
      {trialsCount > 0 && trials.every(item => item.checked) && (
        <Heading
          textStyle="heading"
          as="i"
          lineHeight={1.2}
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
        >
          Reflect and choose more trials ...
        </Heading>
      )}
      <HiddenContent
        visible={
          <SimpleGrid fontSize=".875rem" columns={1} spacing={2}>
            {trials.length > 0 && (
              <TrialsStack
                trials={trials}
                onChange={(val, e) =>
                  handleChange(val, 'checked', e.target.checked)
                }
              />
            )}
          </SimpleGrid>
        }
        hidden={
          <VStack mx={2}>
            {trialResolutions.map((child, i) => (
              <Box key={i}>{child}</Box>
            ))}
          </VStack>
        }
        showHidden={trialResolutions.length > 0}
      />
    </VStack>
  );
}
