import React, { useState } from 'react';
import {
  CloseButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '../theme';

export function ClearableInput({
  label,
  value,
  onChange,
  placeholder,
  ...props
}) {
  const [tempValue, setTempValue] = useState('');

  return (
    <InputGroup size="sm" {...props}>
      <Input
        value={tempValue}
        onChange={e => {
          setTempValue(e.target.value);
          onChange(e.target.value);
        }}
        pr="4.5rem"
        variant="filled"
        rounded="md"
        placeholder={placeholder}
        focusBorderColor={transparentize('accent.500', 0.6)(theme)}
      />
      <InputRightElement mr={1}>
        {tempValue && (
          <CloseButton
            size="sm"
            aria-label={`Clear ${label}`}
            onClick={() => {
              setTempValue('');
              onChange(null);
            }}
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
}
