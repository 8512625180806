import React, { useState } from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';

import { NumberTrack } from './NumberTrack';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function InsightTrack({ value, max, onChange = () => {}, ...props }) {
  const [tempVal, setTempVal] = useState(null);

  const showInsightAdvance =
    (tempVal ?? value) > 0 && (tempVal ?? value) % (max / 3) === 0;

  return (
    <NumberTrack
      value={tempVal || value}
      label="Insight"
      max={max}
      onChange={val => {
        setTempVal(val);
        onChange(val);
      }}
      showHidden={showInsightAdvance}
      hiddenMsg="Insight Advance!"
      {...props}
    >
      <VStack>
        <Heading
          textStyle="heading"
          as="i"
          lineHeight={1.2}
          fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
        >
          Insight Advance!
        </Heading>
        {(tempVal ?? value) === max && (
          <>
            <Text fontSize="sm" as="b">
              Ask another PC:
            </Text>
            <Text as="i">
              What do you like best about the person behind the mirror?
            </Text>
          </>
        )}
      </VStack>
    </NumberTrack>
  );
}

export function DreamMirrorStats(props) {
  const insight = useStoreState(CharacterStore, s => s?.stats?.insight);
  if (insight === null) {
    return null;
  }
  const max = 9;

  return (
    <InsightTrack
      value={insight}
      max={max}
      onChange={val => {
        CharacterStore.update(s => {
          s.stats ?? (s.stats = {});
          s.stats.insight = val;
        });
      }}
      {...props}
    />
  );
}
