import React, { useState } from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import { HiddenContent } from '../../components/HiddenContent';
import { Slider } from '../../components/Slider';

export function NumberTrack({
  value,
  max,
  label,
  onChange,
  showHidden,
  children,
  ...props
}) {
  const [tempValue, setTempValue] = useState(value ?? 0);
  const slider = (
    <VStack>
      <Slider
        value={tempValue}
        max={max}
        ariaPrefix={`${label}: `}
        onChange={val => {
          setTempValue(val);
          onChange(val);
        }}
        useBase
      />
      <Heading
        textStyle="heading"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        {label}
      </Heading>
    </VStack>
  );

  return (
    <HiddenContent visible={slider} hidden={children} showHidden={showHidden} />
  );
}
