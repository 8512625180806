import React from 'react';

import { BeastStats } from './playbookComponents/Beast';
import { ChosenControls, ChosenStats } from './playbookComponents/Chosen';
import { DevotedControls, DevotedStats } from './playbookComponents/Devoted';
import { InfamousControls, InfamousStats } from './playbookComponents/Infamous';
import { ScoundrelButton } from './playbookComponents/Scoundrel';
import { SeekerStats } from './playbookComponents/Seeker';
import { SpookyWitchButton } from './playbookComponents/SpookyWitch';
import { TricksterStats } from './playbookComponents/Trickster';
import { DreamMirrorStats } from './playbookComponents/DreamMirror';
import { EnsembleStats } from './playbookComponents/Ensemble';
import { LegionStats } from './playbookComponents/Legion';
import { NagaStats } from './playbookComponents/Naga';
import { SunHandStats } from './playbookComponents/SunHand';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import {
  NatureWitchControls,
  NatureWitchStats,
} from './playbookComponents/NatureWitch';

const playbookButton = {
  scoundrel: ScoundrelButton,
  'spooky witch': SpookyWitchButton,
};

const playbookControls = {
  chosen: ChosenControls,
  devoted: DevotedControls,
  infamous: InfamousControls,
  'nature witch': NatureWitchControls,
};

const playbookStats = {
  beast: BeastStats,
  chosen: ChosenStats,
  devoted: DevotedStats,
  infamous: InfamousStats,
  'nature witch': NatureWitchStats,
  seeker: SeekerStats,
  trickster: TricksterStats,
  'dream mirror': DreamMirrorStats,
  ensemble: EnsembleStats,
  legion: LegionStats,
  naga: NagaStats,
  'sun hand': SunHandStats,
};

export function hasPlaybookButton(playbook) {
  return Boolean(playbookButton[playbook]);
}

export function PlaybookButton(props) {
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const PlaybookButton = playbookButton[playbook];
  if (!playbook || !PlaybookButton) {
    return null;
  }

  return <PlaybookButton {...props} />;
}

export function hasPlaybookControls(playbook) {
  return Boolean(playbookControls[playbook]);
}

export function PlaybookControls(props) {
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const PlaybookControls = playbookControls[playbook];
  if (!playbook || !PlaybookControls) {
    return null;
  }

  return <PlaybookControls {...props} />;
}

export function hasPlaybookStats(playbook) {
  return Boolean(playbookStats[playbook]);
}

export function PlaybookStats(props) {
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const PlaybookStats = playbookStats[playbook];
  if (!playbook || !PlaybookStats) {
    return null;
  }

  return <PlaybookStats {...props} />;
}
