import { Store } from 'pullstate';
import { db } from './firebase';

export const MessageStore = new Store({
  channel: null,
  messages: undefined,
});

let unsubscribeChannelMessages = () => null;
let messagesRef = null;

export function subscribeChannelMessages(channelName) {
  unsubscribeChannelMessages();

  MessageStore.update(s => {
    s.channel = channelName;
  });

  if (!channelName) {
    return;
  }

  // update message ref
  messagesRef = db.ref(channelName).child('messages');
  unsubscribeChannelMessages = () => messagesRef.off();

  // subscribe to firebase message store
  messagesRef.on('value', function (snapshot) {
    let val = snapshot.val();
    if (!snapshot.exists()) {
      val = [];
    }
    MessageStore.update(s => {
      console.log(`subscribed to ${channelName}`);
      s.messages = val;
    });
  });
}

export function addMessage(messageText) {
  if (!messagesRef) {
    return;
  }
  messagesRef.push().set(messageText);
}

export function removeMessage(messageId) {
  if (!messagesRef) {
    return;
  }
  messagesRef.child(messageId).remove();
}
