import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text } from '@chakra-ui/react';

export function License({ size = 'sm', linkColorScheme, linkVariant }) {
  return (
    <Text fontSize={size}>
      This app is based on{' '}
      <Link
        colorScheme={linkColorScheme}
        variant={linkVariant}
        href="https://thirstyswordlesbians.com"
      >
        Thirsty Sword Lesbians
      </Link>
      , authored by April Kit Walsh, published by Evil Hat Productions, LLC in
      partnership with Gay Spaceship Games, and licensed for use under the{' '}
      <Link
        colorScheme={linkColorScheme}
        variant={linkVariant}
        href="https://creativecommons.org/licenses/by-sa/4.0/"
      >
        Attribution-ShareAlike 4.0 International (CC BY-SA 4.0) license
      </Link>
      . It also features playbooks by Erin Edwards(<i>The Bloody</i>), Christie
      R. Fremon (<i>The Dream Mirror</i>), Katherine Cross (
      <i>The Hologoddess</i>), Ash Cheshire (<i>The Investigator</i>), Pam
      Punzalan (<i>The Legion</i>), Alexis Sara (<i>The Matriarch</i>), Mariam
      Ahmad (<i>The Naga</i>), Jan Martin (<i>The Sun Hand</i>), and Bryanna
      Hitchcock (<i>The Troubadour</i>). TSL is truly a delight, go get yourself
      a copy.
      <br />
      Beautiful dice rolling animations by{' '}
      <Link
        colorScheme={linkColorScheme}
        variant={linkVariant}
        href="https://www.mrroboman.com/"
      >
        Rob Kayson
      </Link>
      .
    </Text>
  );
}

License.propTypes = {
  linkColorScheme: PropTypes.string,
  linkVariant: PropTypes.oneOf(['', 'shadow', 'tealAccent']),
};
