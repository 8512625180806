import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  HStack,
  Input,
  Link,
  Select,
  Stack,
  Text,
  VStack,
  Wrap,
  useColorModeValue,
  useId,
  useNumberInput,
  useRadio,
  useRadioGroup,
  useBreakpointValue,
} from '@chakra-ui/react';
import { transparentize } from '@chakra-ui/theme-tools';
import { theme } from '../theme';

import { ControlledTextarea } from '../components/ControlledInput';
import { EditableHeader } from '../components/EditableHeader';
import { PlaybookControls, hasPlaybookControls } from './PlaybookStats';
import { Advances } from './Advances';
import { Strings, StringsOn } from './Strings';
import { toTitleCase } from '../functions/utils';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../stores/characterStore';
import { GameplayStore, getAdvanceableMoves } from '../stores/gameplayStore';

const ReactMarkdownWithHtml = require('react-markdown/with-html');

function StatBlock({
  name,
  value,
  onChange,
  min,
  max,
  prefix = '',
  direction,
  ...props
}) {
  const format = val => (val > 0 ? prefix + val : val);
  const parse = val => parseInt(val.replace(/^\${prefix}/, ''));
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      value: format(value),
      min: min,
      max: max,
      onChange: valueString => onChange(name, parse(valueString)),
    });
  const buttonVariant = useColorModeValue('subtle', 'solid');
  const buttonProps = {
    size: 'xs',
    colorScheme: 'accent',
    variant: buttonVariant,
  };
  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <Stack align="center" direction={direction} {...props}>
      <HStack minW="120px">
        <Button {...buttonProps} {...dec}>
          -
        </Button>
        <Input {...input} textAlign="center" focusBorderColor="accent.500" />
        <Button {...buttonProps} {...inc}>
          +
        </Button>
      </HStack>
      <Heading size="sm" textStyle="heading">
        {name}
      </Heading>
    </Stack>
  );
}

function Stats(props) {
  const gameStats = useStoreState(GameplayStore, s => s.stats);
  const charStats = useStoreState(CharacterStore, s => s.stats);
  const stats = {
    // [stat1, stat2, stat3] => {stat1: x, stat2: y, stat3: z}
    ...Object.keys(gameStats)?.reduce(
      (o, key) => ({ ...o, [key]: charStats?.[key] ?? 0 }),
      {}
    ),
  };
  const xpValue = useStoreState(CharacterStore, s => s.xp);

  return (
    <VStack spacing={5} mb={3}>
      <StatBlock
        name={'xp'}
        value={xpValue}
        width="90px"
        onChange={(_, value) => {
          CharacterStore.update(s => {
            s.xp = value;
          });
        }}
      />
      {Object.entries(stats).map(([key, value]) => (
        <StatBlock
          key={key}
          name={key}
          value={value}
          prefix={'+'}
          min={-3}
          max={3}
          width="90px"
          onChange={(name, value) => {
            CharacterStore.update(s => {
              s.stats ?? (s.stats = {});
              s.stats[name] = value;
            });
          }}
        />
      ))}
    </VStack>
  );
}

function AestheticEditable({
  name,
  onSubmit = () => {},
  inputWidth,
  inputFocus,
  colorScheme,
  selectedColor,
  ...props
}) {
  const [tempValue, setTempValue] = useState(null);
  const style = name && {
    color: 'white',
    bg: (selectedColor || colorScheme) + '.500',
    padding: '0.25rem',
    rounded: 'lg',
    display: 'inline-block',
  };

  return (
    <Editable
      value={tempValue ?? name}
      onChange={value => setTempValue(value)}
      onSubmit={value => {
        if (value && value !== name) {
          onSubmit(value);
        }
        setTempValue(null);
      }}
      placeholder="or invent your own"
      d="inline"
    >
      <EditablePreview {...style} display={name ? 'inline-block' : 'inline'} />
      <EditableInput w={inputWidth} _focus={inputFocus} />
    </Editable>
  );
}

function AestheticOption(props) {
  const {
    value,
    colorScheme,
    variant,
    selectedColor,
    onClick,
    isChecked, // otherwise: React does not recognize `isChecked`...
    ...rest
  } = props;
  const id = useId(props.id);
  const { getInputProps, getCheckboxProps } = useRadio({ id, ...props });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const buttonProps = {
    'aria-label': value,
    as: 'label',
    htmlFor: input.id,
    variant: variant,
    layerStyle: 'base',
    fontWeight: 'normal',
    _checked: {
      color: 'white',
      bg: (selectedColor || colorScheme) + '.500',
      padding: '0.25rem',
      display: 'inline-block',
    },
  };

  return (
    <>
      <Link
        {...checkbox}
        {...buttonProps}
        onClick={() => onClick(value)}
        {...rest}
        flexWrap="wrap"
        wordBreak="break-word"
        padding={0}
        d="inline"
        rounded="lg"
        height="fit-content"
      >
        {value}
      </Link>
      <span style={{ whiteSpace: 'nowrap', margin: 0 }}>, </span>
      <input {...input} />
    </>
  );
}

function AestheticBlock({ name, options, ...props }) {
  const aesthetic = useStoreState(CharacterStore, s => s.aesthetics?.[name]);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    value: aesthetic,
  });

  const group = getRootProps();
  function handleChange(val, withToggle = false) {
    CharacterStore.update(s => {
      s.aesthetics ?? (s.aesthetics = {});
      if (withToggle && aesthetic === val) {
        s.aesthetics[name] = null;
      } else {
        s.aesthetics[name] = val;
      }
    });
  }

  return (
    <ButtonGroup d="unset" {...group}>
      {options.map(option => (
        <React.Fragment key={option}>
          <AestheticOption
            {...getRadioProps({ value: option })}
            value={option}
            colorScheme="accent"
            variant="link"
            selectedColor="accent"
            children={option}
            onClick={val => handleChange(val, true)}
            height="fit-content"
            p={0}
          />
        </React.Fragment>
      ))}
      <AestheticEditable
        name={options.includes(aesthetic) ? '' : aesthetic}
        onSubmit={handleChange}
        colorScheme="accent"
        selectedColor="accent"
      />
    </ButtonGroup>
  );
}

function Details(props) {
  const name = useStoreState(CharacterStore, s => s.name);
  const pronouns = useStoreState(CharacterStore, s => s.pronouns);
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const playbooks = useStoreState(GameplayStore, s => s.playbooks);
  const aesthetics = playbooks[playbook]?.aesthetics || [];

  const [selectedPlaybook, setSelectedPlaybook] = useState(playbook);

  function handleChange(val) {
    setSelectedPlaybook(val);
    CharacterStore.update(s => {
      s.playbook = val.toLowerCase();
    });
  }

  return (
    <VStack spacing={5} mb={3} height="full">
      <EditableHeader
        name={name || 'Name'}
        onSubmit={val =>
          CharacterStore.update(s => {
            s.name = val;
          })
        }
        className="accentWithHover"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'md' }}
        inputWidth="150px"
      />
      <EditableHeader
        name={pronouns || 'Pronouns'}
        onSubmit={val =>
          CharacterStore.update(s => {
            s.pronouns = val;
          })
        }
        className="accentWithHover"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm' }}
        inputWidth="150px"
      />
      <Select
        value={selectedPlaybook}
        onChange={e => {
          handleChange(e.target.value);
        }}
        w="max-content"
        {...props}
      >
        {Object.keys(playbooks).map(val => (
          <option key={val} value={val}>
            {toTitleCase(val)}
          </option>
        ))}
      </Select>
      <VStack spacing={5} px={3} height="80%" justifyContent="center">
        {aesthetics.map(({ name, options }) => (
          <AestheticBlock
            key={name}
            name={name}
            options={options}
            variant="link"
          />
        ))}
      </VStack>
    </VStack>
  );
}

function Moves(props) {
  const { moves, playbooks } = useStoreState(GameplayStore, s => ({
    moves: s.moves,
    playbooks: s.playbooks,
  }));
  const advanceableMoves = getAdvanceableMoves(moves);
  const { moves: charMoveKeys, playbook } = useStoreState(
    CharacterStore,
    s => ({ moves: s.moves, playbook: s.playbook })
  );
  const [selectedPlaybook, setSelectedPlaybook] = useState(playbook);

  // { moveKey: title}
  const charMoves = (charMoveKeys || []).reduce(
    (o, key) => (moves[key] ? { ...o, [key]: moves[key].title } : { ...o }),
    {}
  );
  const untakenMoves = Object.keys(advanceableMoves).reduce(
    (o, key) =>
      charMoves.hasOwnProperty(key) ||
      moves[key].type !== selectedPlaybook ||
      (moves[key].type === playbook) & (moves[key].subtype === 'default')
        ? { ...o }
        : { ...o, [key]: moves[key].title },
    {}
  );

  const MoveCheckbox = (key, isChecked, title) => (
    <Checkbox
      key={key}
      isChecked={isChecked}
      colorScheme="accent"
      onChange={e => {
        CharacterStore.update(s => {
          s.moves = s.moves ?? [];
          if (e.target.checked) {
            s.moves.includes(key) || s.moves.push(key);
          } else {
            s.moves = s.moves.filter(item => item !== key);
          }
        });
      }}
    >
      {title}
    </Checkbox>
  );

  return (
    <Center>
      <Stack>
        <Heading textStyle="heading" size="sm" className="accentWithHover">
          Taken
        </Heading>
        {Object.entries(charMoves).map(([key, title]) =>
          MoveCheckbox(key, true, title)
        )}
        <Heading
          textStyle="heading"
          size="sm"
          className="accentWithHover"
          pt={5}
        >
          Untaken
        </Heading>
        <Select
          value={selectedPlaybook}
          onChange={e => {
            setSelectedPlaybook(e.target.value);
          }}
          w="max-content"
          {...props}
        >
          {Object.keys(playbooks).map(val => (
            <option key={val} value={val}>
              {toTitleCase(val)}
            </option>
          ))}
        </Select>
        {Object.entries(untakenMoves).map(([key, title]) =>
          MoveCheckbox(key, false, title)
        )}
      </Stack>
    </Center>
  );
}

function Relationships(props) {
  const relationships = useStoreState(
    CharacterStore,
    s => s.relationships || []
  );
  const playbook = useStoreState(CharacterStore, s => s.playbook);
  const relationshipQuestions = useStoreState(
    GameplayStore,
    s => s.playbooks[playbook]?.relationships || [],
    [playbook]
  );

  // use questions as keys in order to combine data
  const questions = {
    // current playbook's relationships first, regardelss of whether they have
    // been filled out
    ...relationshipQuestions.reduce(
      (o, question, i) => ({
        ...o,
        [question]: { question: question, id: `${playbook}-${i}` },
      }),
      {}
    ),
    // put most recent relationships first
    ...relationships.reduceRight(
      (o, relationship) => ({
        ...o,
        [relationship.question]: relationship,
      }),
      {}
    ),
  };

  let handleInputChange = val => {
    CharacterStore.update(s => {
      s.relationships ?? (s.relationships = []);
      if (s.relationships.find(item => item?.question === val.question)) {
        // remove relationship if empty
        if (!val.answer.trim()) {
          s.relationships = s.relationships.filter(
            item => item.question !== val.question
          );
          // modify relationship
        } else {
          s.relationships = s.relationships.map(item =>
            item.question === val.question ? val : item
          );
          // add
        }
      } else {
        s.relationships.push(val);
      }
    });
  };

  return (
    <Stack>
      {Object.values(questions).map((item, i) => (
        <React.Fragment key={item.id || i}>
          <Text as="i" key={item.id || i}>
            <ReactMarkdownWithHtml
              children={item.question}
              allowDangerousHtml
            />
          </Text>
          <ControlledTextarea
            value={item.answer}
            debounceMs={300}
            onChange={answer => handleInputChange({ ...item, answer: answer })}
            size="sm"
            _focus={{
              boxShadow: `0 0 0 3px ${transparentize(
                'accent.500',
                0.3
              )(theme)}`,
            }}
            {...props}
          />
        </React.Fragment>
      ))}
    </Stack>
  );
}

function Notes(props) {
  const notes = useStoreState(CharacterStore, s => s.notes);

  let handleInputChange = val => {
    CharacterStore.update(s => {
      s.notes = val;
    });
  };

  return (
    <ControlledTextarea
      value={notes}
      onChange={value => handleInputChange(value)}
      debounceMs={300}
      placeholder="Character notes"
      size="sm"
      height="full"
      _focus={{
        boxShadow: `0 0 0 3px ${transparentize('accent.500', 0.3)(theme)}`,
      }}
      {...props}
    />
  );
}

function Card({ title, children, ...props }) {
  return (
    <Stack
      orientation="column"
      p={4}
      maxWidth="500px"
      spacing={2}
      align="center"
      rounded="lg"
      //bgColor="rgba(0,0,0, 0.2)"
      {...props}
    >
      <Heading textStyle="heading" size="sm" className="accentWithHover" mb={2}>
        {title}
      </Heading>
      {children}
    </Stack>
  );
}

export function Character(props) {
  const { name, playbook, gmMode } = useStoreState(CharacterStore, s => {
    return { name: s.name, playbook: s.playbook, gmMode: s.GM };
  });
  const shadowColor = useColorModeValue('0, 0, 0', '255, 255, 255');
  const css = useBreakpointValue({
    base: { '& > ul > li:before': { content: '""' } },
    md: {
      '& > ul > li:before': { content: '""' },
      boxShadow: `0 4px 8px 0 rgba(${shadowColor}, 0.2), 0 6px 20px 0 rgba(${shadowColor}, 0.19)`,
    },
  });

  return (
    <Wrap
      spacing={1}
      height="fit-content"
      shouldWrapChildren
      css={css}
      justify="center"
      rounded="xl"
      m={4}
      p={0}
      pb={2}
    >
      <Card title="" minWidth="300px" maxWidth="330px" height="90%">
        <Details />
      </Card>
      <Card title="Stats" minWidth="200px" maxWidth="330px">
        <Stats />
      </Card>
      {hasPlaybookControls(playbook) && (
        <Card title={toTitleCase(playbook)} minWidth="400px" maxWidth="440px">
          <PlaybookControls />
        </Card>
      )}
      <Card title="Strings" minWidth="300px" maxWidth="330px">
        <Strings />
      </Card>
      {gmMode && (
        <Card title={`Strings On ${name}`} minWidth="300px" maxWidth="330px">
          <StringsOn />
        </Card>
      )}
      <Card
        title="Relationships"
        minWidth="330px"
        maxWidth="500px"
        height="100%"
      >
        <Relationships />
      </Card>
      <Card title="Notes" minWidth="330px" maxWidth="500px" height="100%">
        <Notes />
      </Card>
      <Card title="Moves" minWidth="300px" maxWidth="400px">
        <Moves />
      </Card>
      <Card title="Advances" minWidth="300px" maxWidth="330px">
        <Advances px={5} pb={3} />
      </Card>
    </Wrap>
  );
}
