import React from 'react';
import { Heading } from '@chakra-ui/react';

import { NumberTrack } from './NumberTrack';

import { useStoreState } from 'pullstate';
import { CharacterStore } from '../../stores/characterStore';

export function TricksterStats(props) {
  const feelings = useStoreState(CharacterStore, s => s?.stats?.feelings);
  if (feelings === null) {
    return null;
  }
  const max = 4;
  const showMsg = feelings === max;

  return (
    <NumberTrack
      value={feelings}
      label="Feelings"
      onChange={val => {
        CharacterStore.update(s => {
          s.stats ?? (s.stats = {});
          s.stats.feelings = val;
        });
      }}
      showHidden={showMsg}
      {...props}
    >
      <Heading
        textStyle="heading"
        as="i"
        lineHeight={1.2}
        fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}
      >
        Let It Out!
      </Heading>
    </NumberTrack>
  );
}
